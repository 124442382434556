import React, { useState } from "react"
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"

import { Link } from "react-router-dom"

// users
import { route, routes } from "helpers/routeHelper"
import { useAuth } from "context/auth"
import UserAvatar from "components/Shared/UserAvatar"
import { getInitialsFromName } from "helpers/utilHelper"

const ProfileMenu = () => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false);
  const { user: authUser } = useAuth();

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item "
          id="page-header-user-dropdown"
          tag="button"
        >
          <UserAvatar id={authUser.id} image={authUser.image} initials={getInitialsFromName(authUser.fullName)} size="sm" />
          <span className="d-none d-xl-inline-block ms-2 me-1 auth-user-name">{authUser.getFullName()}<small className="auth-user-role text-muted">{authUser.userRoleName}</small></span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <DropdownItem tag="span" className="text-muted font-size-12">{authUser.email}</DropdownItem>
          <DropdownItem tag="a" href={route(routes.view_me)}>
            {" "}
            <i className="bx bx-user font-size-16 align-middle me-1" />
            Profile{" "}
          </DropdownItem>
          <div className="dropdown-divider" />
          <Link to="/logout" className="dropdown-item">
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>Logout</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}

export default ProfileMenu;
