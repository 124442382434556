export const GET_AUTH_USER = 'GET_AUTH_USER'
export const GET_AUTH_USER_OK = 'GET_AUTH_USER_OK'
export const GET_AUTH_USER_ERR = 'GET_AUTH_USER_ERR'

export const LOGIN_USER = 'LOGIN_USER'
export const LOGIN_USER_OK = 'LOGIN_USER_OK'
export const LOGIN_USER_ERR = 'LOGIN_USER_ERR'
export const SAVE_RETURN_URL = 'SAVE_RETURN_URL'
export const DO_LOGIN_USER_CLEANUP = 'DO_LOGIN_USER_CLEANUP'

export const LOGOUT_USER = 'LOGOUT_USER'
export const LOGOUT_USER_OK = 'LOGOUT_USER_OK'
export const LOGOUT_USER_ERR = 'LOGOUT_USER_ERR'

export const REFRESH_AUTH_USER = 'REFRESH_AUTH_USER'
export const REFRESH_AUTH_USER_OK = 'REFRESH_AUTH_USER_OK'
export const REFRESH_AUTH_USER_ERR = 'REFRESH_AUTH_USER_ERR'

export const VALIDATE_VERIF_CODE = 'VALIDATE_VERIF_CODE'
export const VALIDATE_VERIF_CODE_OK = 'VALIDATE_VERIF_CODE_OK'
export const VALIDATE_VERIF_CODE_ERR = 'VALIDATE_VERIF_CODE_ERR'

export const RESEND_VERIF_CODE = 'RESEND_VERIF_CODE'
export const RESEND_VERIF_CODE_OK = 'RESEND_VERIF_CODE_OK'
export const RESEND_VERIF_CODE_ERR = 'RESEND_VERIF_CODE_ERR'

export const DO_VERIF_CODE_CLEANUP = 'DO_VERIF_CODE_CLEANUP'