import {
  GET_AUTH_USER,
  GET_AUTH_USER_OK,
  GET_AUTH_USER_ERR,
  LOGIN_USER,
  LOGIN_USER_OK,
  LOGIN_USER_ERR,
  SAVE_RETURN_URL,
  DO_LOGIN_USER_CLEANUP,
  LOGOUT_USER,
  LOGOUT_USER_OK,
  LOGOUT_USER_ERR,
  REFRESH_AUTH_USER,
  REFRESH_AUTH_USER_OK,
  REFRESH_AUTH_USER_ERR,
  VALIDATE_VERIF_CODE,
  VALIDATE_VERIF_CODE_OK,
  VALIDATE_VERIF_CODE_ERR,
  RESEND_VERIF_CODE,
  RESEND_VERIF_CODE_OK,
  RESEND_VERIF_CODE_ERR,
  DO_VERIF_CODE_CLEANUP,
} from "./actionTypes"

export const getAuthUser = () => ({
  type: GET_AUTH_USER,
});

export const getAuthUserOk = response => ({
  type: GET_AUTH_USER_OK,
  payload: { response },
});

export const getAuthUserErr = error => ({
  type: GET_AUTH_USER_ERR,
  payload: { error },
});

export const loginUser = data => ({
  type: LOGIN_USER,
  payload: { data },
});

export const loginUserOk = response => ({
  type: LOGIN_USER_OK,
  payload: { response },
});

export const loginUserErr = error => ({
  type: LOGIN_USER_ERR,
  payload: { error },
});

export const saveReturnUrl = url => ({
  type: SAVE_RETURN_URL,
  payload: { url },
});

export const doLoginUserCleanup = () => ({
  type: DO_LOGIN_USER_CLEANUP,
});

export const logoutUser = user => ({
  type: LOGOUT_USER,
  payload: { user },
});

export const logoutUserOk = () => ({
  type: LOGOUT_USER_OK,
});

export const logoutUserErr = error => ({
  type: LOGOUT_USER_ERR,
  payload: { error },
});

export const refreshAuthUser = () => ({
  type: REFRESH_AUTH_USER,
});

export const refreshAuthUserOk = response => ({
  type: REFRESH_AUTH_USER_OK,
  payload: { response },
});

export const refreshAuthUserErr = error => ({
  type: REFRESH_AUTH_USER_ERR,
  payload: { error },
});

export const validateVerifCode = data => ({
  type: VALIDATE_VERIF_CODE,
  payload: { data },
});

export const validateVerifCodeOk = response => ({
  type: VALIDATE_VERIF_CODE_OK,
  payload: { response },
});

export const validateVerifCodeErr = error => ({
  type: VALIDATE_VERIF_CODE_ERR,
  payload: { error },
});

export const resendVerifCode = () => ({
  type: RESEND_VERIF_CODE,
});

export const resendVerifCodeOk = response => ({
  type: RESEND_VERIF_CODE_OK,
  payload: { response },
});

export const resendVerifCodeErr = error => ({
  type: RESEND_VERIF_CODE_ERR,
  payload: { error },
});

export const doVerifCodeCleanup = () => ({
  type: DO_VERIF_CODE_CLEANUP,
});