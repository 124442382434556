import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col } from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb2";
import MetaTitle from "components/Shared/MetaTitle";
import { doUserSingleCleanup, getUser } from "store/actions";
import { route, routes } from "helpers/routeHelper";
import Error from "pages/Error";
import Preloader from "components/Shared/Preloader";
import { perms, useAccess } from "context/access";
import AccessDenied from "pages/Error/AccessDenied";
import { useAuth } from "context/auth";
import UserPasswordForm from "./Partial/FormPassword";

const UserPassword = () => {

  // redux hook that dispatches actions
  const dispatch = useDispatch();
  // hooks that check permissions
  const { iAmGranted, iAmNotGranted } = useAccess();
  // authenticated user
  const { user: authUser } = useAuth();

  /********** STATE **********/

  // get redux state from the store
  const { user, userError, isBusy } = useSelector(state => state.User.Single);

  /********** EFFECTS **********/

  // runs once on component mount
  useEffect(() => {
    // make the initial remote call to get the user data
    dispatch(getUser(authUser.id));
    return () => {
      // state cleanup on component unmount
      dispatch(doUserSingleCleanup());
    }
  }, [authUser]);

  return <React.Fragment>
    {iAmGranted(perms.edit_my_account) && <div className="page-content">
      {user && <>
        <MetaTitle>Edit {user.fullName}</MetaTitle>
        <Container fluid>
          <Breadcrumbs title="Change account password" breadcrumbItems={breadcrumbs(user)} />
          <Row>
            <Col xl="8" xxl="6">
              <UserPasswordForm
                title={user.fullName}
                id={parseInt(user.id)}
                successRoute={route(routes.view_me)}
                cancelRoute={route(routes.view_me)} />
            </Col>
          </Row>
        </Container>
      </>}
      {isBusy && <Preloader className="inner" />}
      {userError && <Error error={userError} title404="User not found" />}
    </div>}
    {iAmNotGranted(perms.edit_my_account) && <AccessDenied />}
  </React.Fragment>
}

const breadcrumbs = user => [{
  title: 'Dashboard',
  url: route(routes.home),
}, {
  title: 'My account',
  url: route(routes.view_me),
}, {
  title: 'Change password',
  url: route(routes.change_my_password),
}];

export default UserPassword;
