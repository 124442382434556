import React from 'react';
import { useAuth } from './auth';
import { getAdminAppUrl, getDealerAppUrl, getNotaryAppUrl } from 'helpers/utilHelper';

export const perms = {
  view_my_account: 'view_my_account',
  edit_my_account: 'edit_my_account',
};

const AccessContext = React.createContext();

const AccessProvider = props => {

  const { user: authUser } = useAuth();

  const iAmAdmin = () => authUser && authUser.isAdmin();
  const iAmScheduler = () => authUser && authUser.isScheduler();
  const iAmDealerGroupManager = () => authUser && authUser.isDealerGroupManager();
  const iAmDealerRegionalManager = () => authUser && authUser.isDealerRegionalManager();
  const iAmDealerStoreManager = () => authUser && authUser.isDealerStoreManager();
  const iAmDealerFinanceManager = () => authUser && authUser.isDealerFinanceManager();
  const iAmDealerSalesManager = () => authUser && authUser.isDealerSalesManager();
  const iAmDealerUpperManager = () => authUser && authUser.isDealerUpperManager();
  const iAmDealerLowerManager = () => authUser && authUser.isDealerLowerManager();
  const iAmDealerManager = () => authUser && authUser.isDealerManager();
  const iAmDealerStoreUser = () => authUser && authUser.isDealerStoreUser();
  const iAmDealerStoreLimitedUser = () => authUser && authUser.isDealerStoreLimitedUser();

  const iAmAdminType = () => authUser && authUser.isAdminType();
  const iAmDealerType = () => authUser && authUser.isDealerType();
  const iAmNotaryType = () => authUser && authUser.isNotaryType();

  const iAmGranted = (attribute, subject) => {
    switch (attribute) {
      case perms.view_my_account:
      case perms.edit_my_account:
        return true;
      default:
        return false;
    }
  }

  const iAmNotGranted = (attribute, subject) => !iAmGranted(attribute, subject);

  const isAnyGranted = (attributes, subject) => attributes.some(attr => iAmGranted(attr, subject));

  const getUserAppUrl = fragment => {
    if (iAmAdminType()) {
      return getAdminAppUrl(fragment);
    } else if (iAmDealerType()) {
      return getDealerAppUrl(fragment);
    } else if (iAmNotaryType()) {
      return getNotaryAppUrl(fragment);
    }
  }

  const contextValue = {
    iAmGranted,
    iAmNotGranted,
    isAnyGranted,
    iAmAdmin,
    iAmScheduler,
    iAmDealerGroupManager,
    iAmDealerRegionalManager,
    iAmDealerStoreManager,
    iAmDealerFinanceManager,
    iAmDealerSalesManager,
    iAmDealerUpperManager,
    iAmDealerLowerManager,
    iAmDealerManager,
    iAmDealerStoreUser,
    iAmAdminType,
    iAmDealerType,
    getUserAppUrl,
  };

  return <AccessContext.Provider value={contextValue} {...props} />
}

// helper hook that makes context data available
export const useAccess = () => React.useContext(AccessContext);

export default AccessProvider;