import { takeEvery, put, call } from "redux-saga/effects";

import {
  GET_USER,
  SAVE_AUTH_USER,
  SAVE_AUTH_USER_PWD,
  UPLOAD_AUTH_USER_IMG,
  DELETE_AUTH_USER_IMG,
  REQUEST_PASSWORD_RESET,
  PRE_RESET_PASSWORD,
  RESET_PASSWORD,
  PRE_ACTIVATE_ACCOUNT,
  ACTIVATE_ACCOUNT,
  VERIFY_EMAIL,
} from "./actionTypes";

import {
  getUserOk,
  getUserErr,
  saveUserOk,
  saveUserErr,
  uploadAuthUserImgOk,
  uploadAuthUserImgErr,
  deleteAuthUserImgOk,
  deleteAuthUserImgErr,
  requestPasswordResetOk,
  requestPasswordResetErr,
  preResetPasswordOk,
  preResetPasswordErr,
  resetPasswordOk,
  resetPasswordErr,
  preActivateAccountOk,
  preActivateAccountErr,
  activateAccountOk,
  activateAccountErr,
  verifyEmailOk,
  verifyEmailErr,
} from "./actions";

import {
  getUser,
  saveAuthUser,
  saveAuthUserPwd,
  uploadAuthUserImg,
  deleteAuthUserImg,
  requestPasswordReset,
  preResetPassword,
  resetPassword,
  preActivateAccount,
  activateAccount,
  verifyEmail,
} from "helpers/backendHelper";

function* onGetUser({ payload: { id } }) {
  try {
    const response = yield call(getUser, id);
    yield put(getUserOk(response));
  } catch (error) {
    yield put(getUserErr(error));
  }
}

function* onSaveAuthUser({ payload: { data } }) {
  try {
    yield call(saveAuthUser, data);
    yield put(saveUserOk());
  } catch (error) {
    yield put(saveUserErr(error));
  }
}

function* onSaveAuthUserPwd({ payload: { data } }) {
  try {
    yield call(saveAuthUserPwd, data);
    yield put(saveUserOk());
  } catch (error) {
    yield put(saveUserErr(error));
  }
}

function* onUploadAuthUserImg({ payload: { data } }) {
  try {
    yield call(uploadAuthUserImg, data);
    yield put(uploadAuthUserImgOk());
  } catch (error) {
    yield put(uploadAuthUserImgErr(error));
  }
}

function* onDeleteAuthUserImg() {
  try {
    yield call(deleteAuthUserImg);
    yield put(deleteAuthUserImgOk());
  } catch (error) {
    yield put(deleteAuthUserImgErr(error));
  }
}

function* onRequestPasswordReset({ payload: { data } }) {
  try {
    const response = yield call(requestPasswordReset, data);
    yield put(requestPasswordResetOk(response));
  } catch (error) {
    yield put(requestPasswordResetErr(error));
  }
}

function* onPreResetPassword({ payload: { token } }) {
  try {
    const response = yield call(preResetPassword, token);
    yield put(preResetPasswordOk(response));
  } catch (error) {
    yield put(preResetPasswordErr(error));
  }
}

function* onResetPassword({ payload: { token, data } }) {
  try {
    const response = yield call(resetPassword, token, data);
    yield put(resetPasswordOk(response));
  } catch (error) {
    yield put(resetPasswordErr(error));
  }
}

function* onPreActivateAccount({ payload: { token } }) {
  try {
    const response = yield call(preActivateAccount, token);
    yield put(preActivateAccountOk(response));
  } catch (error) {
    yield put(preActivateAccountErr(error));
  }
}

function* onActivateAccount({ payload: { token, data } }) {
  try {
    const response = yield call(activateAccount, token, data);
    yield put(activateAccountOk(response));
  } catch (error) {
    yield put(activateAccountErr(error));
  }
}

function* onVerifyEmail({ payload: { token } }) {
  try {
    const response = yield call(verifyEmail, token);
    yield put(verifyEmailOk(response));
  } catch (error) {
    yield put(verifyEmailErr(error));
  }
}

function* userSaga() {
  yield takeEvery(GET_USER, onGetUser);
  yield takeEvery(SAVE_AUTH_USER, onSaveAuthUser);
  yield takeEvery(SAVE_AUTH_USER_PWD, onSaveAuthUserPwd);
  yield takeEvery(UPLOAD_AUTH_USER_IMG, onUploadAuthUserImg);
  yield takeEvery(DELETE_AUTH_USER_IMG, onDeleteAuthUserImg);
  yield takeEvery(REQUEST_PASSWORD_RESET, onRequestPasswordReset);
  yield takeEvery(PRE_RESET_PASSWORD, onPreResetPassword);
  yield takeEvery(RESET_PASSWORD, onResetPassword);
  yield takeEvery(PRE_ACTIVATE_ACCOUNT, onPreActivateAccount);
  yield takeEvery(ACTIVATE_ACCOUNT, onActivateAccount);
  yield takeEvery(VERIFY_EMAIL, onVerifyEmail);
}

export default userSaga;
