/********** SINGLE **********/

export const GET_USER = 'GET_USER'
export const GET_USER_OK = 'GET_USER_OK'
export const GET_USER_ERR = 'GET_USER_ERR'
export const DO_USER_SINGLE_CLEANUP = 'DO_USER_SINGLE_CLEANUP'

/********** FORM **********/

export const SAVE_AUTH_USER = 'SAVE_AUTH_USER'
export const SAVE_AUTH_USER_PWD = 'SAVE_AUTH_USER_PWD'
export const SAVE_USER_OK = 'SAVE_USER_OK'
export const SAVE_USER_ERR = 'SAVE_USER_ERR'
export const DO_USER_FORM_CLEANUP = 'DO_USER_FORM_CLEANUP'

export const UPLOAD_AUTH_USER_IMG = 'UPLOAD_AUTH_USER_IMG'
export const UPLOAD_AUTH_USER_IMG_OK = 'UPLOAD_AUTH_USER_IMG_OK'
export const UPLOAD_AUTH_USER_IMG_ERR = 'UPLOAD_AUTH_USER_IMG_ERR'
export const DELETE_AUTH_USER_IMG = 'DELETE_AUTH_USER_IMG'
export const DELETE_AUTH_USER_IMG_OK = 'DELETE_AUTH_USER_IMG_OK'
export const DELETE_AUTH_USER_IMG_ERR = 'DELETE_AUTH_USER_IMG_ERR'
export const DO_AUTH_USER_IMG_CLEANUP = 'DO_AUTH_USER_IMG_CLEANUP'

/********** VALIDATE JWT **********/

export const PRE_ACTIVATE_ACCOUNT = 'PRE_ACTIVATE_ACCOUNT'
export const PRE_ACTIVATE_ACCOUNT_OK = 'PRE_ACTIVATE_ACCOUNT_OK'
export const PRE_ACTIVATE_ACCOUNT_ERR = 'PRE_ACTIVATE_ACCOUNT_ERR'

export const PRE_RESET_PASSWORD = 'PRE_RESET_PASSWORD'
export const PRE_RESET_PASSWORD_OK = 'PRE_RESET_PASSWORD_OK'
export const PRE_RESET_PASSWORD_ERR = 'PRE_RESET_PASSWORD_ERR'

export const DO_JWT_VALIDATION_CLEANUP = 'DO_JWT_VALIDATION_CLEANUP'

/********** RESET PASSWORD **********/

export const REQUEST_PASSWORD_RESET = 'REQUEST_PASSWORD_RESET'
export const REQUEST_PASSWORD_RESET_OK = 'REQUEST_PASSWORD_RESET_OK'
export const REQUEST_PASSWORD_RESET_ERR = 'REQUEST_PASSWORD_RESET_ERR'

export const RESET_PASSWORD = 'RESET_PASSWORD'
export const RESET_PASSWORD_OK = 'RESET_PASSWORD_OK'
export const RESET_PASSWORD_ERR = 'RESET_PASSWORD_ERR'

export const DO_PASSWORD_RESET_CLEANUP = 'DO_PASSWORD_RESET_CLEANUP'

/********** ACTIVATE ACCOUNT **********/

export const ACTIVATE_ACCOUNT = 'ACTIVATE_ACCOUNT'
export const ACTIVATE_ACCOUNT_OK = 'ACTIVATE_ACCOUNT_OK'
export const ACTIVATE_ACCOUNT_ERR = 'ACTIVATE_ACCOUNT_ERR'
export const DO_ACCOUNT_ACTIVATION_CLEANUP = 'DO_ACCOUNT_ACTIVATION_CLEANUP'

/********** VERIFY EMAIL **********/

export const VERIFY_EMAIL = 'VERIFY_EMAIL'
export const VERIFY_EMAIL_OK = 'VERIFY_EMAIL_OK'
export const VERIFY_EMAIL_ERR = 'VERIFY_EMAIL_ERR'
export const DO_EMAIL_VERIFICATION_CLEANUP = 'DO_EMAIL_VERIFICATION_CLEANUP'