import { combineReducers } from "redux"

// Front
import Layout from "./layout/reducer"
import Auth from "./auth/reducer"
import User from "./user/reducer"

export default combineReducers({
  Layout,
  Auth,
  User,
})
