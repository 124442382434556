import { del, get, post, put } from "./apiHelper";
import { withAuth } from "./utilHelper";

export const getAuthUser = () => get('/me');

export const saveAuthUser = data => put('/me', data);

export const saveAuthUserPwd = data => put('/me/password', data);

export const uploadAuthUserImg = data => post('/me/image', data, { headers: { 'Content-Type': 'multipart/form-data' } });

export const deleteAuthUserImg = () => del('/me/image');

export const loginUser = data => post('/login', data);

export const logoutUser = () => post(withAuth('/logout'));

export const validateVerifCode = data => post(withAuth('/2fa/validate'), data);

export const resendVerifCode = () => post(withAuth('/2fa/restart'));

export const getUser = id => get(`/user/${id}`);

export const requestPasswordReset = data => post('/user/request-password-reset', data);

export const preResetPassword = token => post(`/user/pre-reset-password/${token}`);

export const resetPassword = (token, data) => put(`/user/reset-password/${token}`, data);

export const preActivateAccount = token => post(`/user/pre-activate-account/${token}`);

export const activateAccount = (token, data) => put(`/user/activate-account/${token}`, data);

export const verifyEmail = token => put(`/user/verify-email/${token}`);