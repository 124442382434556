import React from "react";
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import { Card, CardBody, Row, Col, Button, CardHeader } from "reactstrap";
import { route, routes } from "helpers/routeHelper";
import { perms, useAccess } from "context/access";
import { formats, formatTimestamp } from "helpers/dateHelper";
import moment from "moment-timezone";
import { getTimezoneAbbr } from "helpers/utilHelper";

const UserProfileView = props => {

  // hooks that check permissions
  const { iAmGranted } = useAccess();

  const user = props.user;
  const toggleEditMode = props.toggleEditMode;

  return <React.Fragment>
    <Card className="expand-v">
      <CardHeader className="bg-transparent pt-3">
        <Row>
          <Col>
            <h3>{user.fullName}</h3>
          </Col>
          <Col xs="auto">
            <div className="text-end">
              {iAmGranted(perms.edit_my_account) && <Button type="button" color="primary" className="btn ms-2 mb-2" onClick={toggleEditMode}>
                <i className="mdi mdi-pencil me-1" />Edit account
              </Button>}
              {iAmGranted(perms.edit_my_account) && <Link to={route(routes.change_my_password)} className="btn btn-info ms-2 mb-2">
                <i className="mdi mdi-key me-1" />Change password
              </Link>}
            </div>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <Row>
          <Col xs="12" xl="4">
            <Row className="mb-3">
              <label className="mb-0">Title</label>
              <div>{user.title ?? '--'}</div>
            </Row>
            <Row className="mb-3">
              <label className="mb-0">Role</label>
              <div>{user.userRoleName}</div>
            </Row>
          </Col>
          <Col xs="12" xl="4">
            <Row className="mb-3">
              <label className="mb-0">Email</label>
              <div>{user.email}</div>
            </Row>
            <Row className="mb-3">
              <label className="mb-0">Phone</label>
              <div>{user.phone ?? '--'}</div>
            </Row>
            <Row className="mb-3">
              <label className="mb-0">Timezone</label>
              <div>{user.timezone} ({getTimezoneAbbr(user.timezone)})</div>
            </Row>
          </Col>
          {user.isDealerType() && <Col xs="12" xl="4">
            <Row className="mb-3">
              <label className="mb-0">Group</label>
              <div>{user.dealerGroupName ?? '--'}</div>
            </Row>
            <Row className="mb-3">
              <label className="mb-0">Region</label>
              <div>{user.dealerRegionName ?? '--'}</div>
            </Row>
            <Row className="mb-3">
              <label className="mb-0">Store</label>
              <div>{user.dealerStoreName ?? '--'}</div>
            </Row>
          </Col>}
        </Row>
        <Row className="mt-4">
          <Col xs="4">
            <h5 className="font-size-14"><i className="bx bx-calendar me-1 text-primary"></i> Created</h5>
            <p className="text-muted mb-0">{formatTimestamp(user.createdTs, formats.DATETIME)}</p>
          </Col>
          <Col xs="4">
            <h5 className="font-size-14"><i className="bx bx-calendar me-1 text-primary"></i> Updated</h5>
            <p className="text-muted mb-0">{formatTimestamp(user.updatedTs, formats.DATETIME)}</p>
          </Col>
          <Col xs="4">
            <h5 className="font-size-14"><i className="bx bx-calendar me-1 text-primary"></i> Last login</h5>
            <p className="text-muted mb-0">{user.lastAuthTs ? formatTimestamp(user.lastAuthTs, formats.DATETIME) : '--'}</p>
          </Col>
        </Row>
      </CardBody>
    </Card>
  </React.Fragment>
}

UserProfileView.propTypes = {
  user: PropTypes.object,
  toggleEditMode: PropTypes.func,
};

export default UserProfileView;