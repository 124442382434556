import React, { useEffect } from "react";
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Card, CardBody, Row, Col, Button, CardHeader, Form, Label, Input, FormFeedback } from "reactstrap";
import Select from "react-select";
import { getTimezoneOptions, nullsToEmptyStrings, phoneHasNoOfDigits, showBriefError, showError, showSuccess } from "helpers/utilHelper";
import { doUserFormCleanup, refreshAuthUser, saveAuthUser } from "store/actions";
import { ValidationException } from "helpers/errorHelper";
import regx from "constants/regx";

const UserForm = props => {

  // redux hook that dispatches actions
  const dispatch = useDispatch();

  // this is a callback that should be called when the user either cancels the form or saves it successfully
  const finishedHandler = props.finishedHandler;

  /********** STATE **********/

  // get redux state from the store
  const { isBusy, saved, saveError } = useSelector(state => state.User.Form);

  /********** FORM CONFIG **********/

  const formInitialValues = {
    firstName: '',
    lastName: '',
    email: '',
    title: '',
    phone: '',
    timezone: '',
    ...nullsToEmptyStrings(props.defaultValues),
  };

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: formInitialValues,
    validationSchema: Yup.object({
      firstName: Yup.string().trim().required('Field is required'),
      lastName: Yup.string().trim().required('Field is required'),
      email: Yup.string().trim().required('Field is required').email('Invalid email address'),
      title: Yup.string().trim(),
      phone: Yup.string().trim().required('Field is required').matches(regx.phone, 'Invalid phone number').test('phone',
        'Field requires exactly 10 digits',
        ((value) => phoneHasNoOfDigits(value))
      ),
      timezone: Yup.string().required('Field is required'),
    }),
    onSubmit: values => dispatch(saveAuthUser(values)),
  });

  /********** EFFECTS **********/

  // runs once on component mount
  useEffect(() => {
    return () => {
      // state cleanup on component unmount
      // the Form component does not have much redux state
      // but we need to clear any server validation errors
      dispatch(doUserFormCleanup());
    }
  }, []);

  // runs whenever the 'saved' flag changes
  // which happens after a save-user attempt
  useEffect(() => {
    if (saved === true) {
      showSuccess(`Account has been saved`);
      // re-fetch the user information from api so the user name is updated in the profile menu
      dispatch(refreshAuthUser());
      finishedHandler();
    } else if (saved === false) {
      showError('Unable to save account');
      // see if the save failed due to validation
      if (saveError instanceof ValidationException) {
        // show an error on each invalid field
        for (const [name, message] of Object.entries(saveError.fields)) {
          formik.setFieldError(name, message);
        }
      }
      // enable the save button
      formik.setSubmitting(false);
    }
  }, [saved]);

  // runs whenever the validation fails
  useEffect(() => {
    if (!formik.isValid) {
      showBriefError('Form has errors');
    }
  }, [formik.isValid]);

  /********** EVENT HANDLERS **********/

  // focus event handler
  // used to clear field errors
  const onFieldFocused = (e, fieldName) => {
    const name = fieldName || e.target.name;
    const errors = formik.errors;
    delete errors[name];
    formik.setStatus(errors);
  };

  return <React.Fragment>
    <Card>
      <CardHeader className="bg-transparent pt-3">
        <Row>
          <Col>
            <h3>{props.title}</h3>
          </Col>
          <Col xs="auto">
            <div className="text-end">
              <Button type="button" color="primary" className="mb-2" onClick={formik.handleSubmit} disabled={formik.isSubmitting}>
                {isBusy && <i className="mdi mdi-spin mdi-loading me-1" />}
                {!isBusy && <i className="mdi mdi-check me-1" />}
                Save account
              </Button>
              <Button type="button" color="secondary" className="ms-2 mb-2" onClick={finishedHandler}>
                <i className="mdi mdi-chevron-left me-1" />Cancel
              </Button>
            </div>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <Row>
          <Col>
            <Form>
              <Row className="mb-4">
                <Label className="col-sm-3 col-form-label">First name *</Label>
                <Col sm={9}>
                  <Input type="text" className="form-control" placeholder="ex. John" name="firstName" onChange={formik.handleChange} onFocus={onFieldFocused} value={formik.values.firstName} invalid={!!formik.errors.firstName} />
                  {!!formik.errors.firstName && <FormFeedback type="invalid">{formik.errors.firstName}</FormFeedback>}
                </Col>
              </Row>
              <Row className="mb-4">
                <Label className="col-sm-3 col-form-label">Last name *</Label>
                <Col sm={9}>
                  <Input type="text" className="form-control" placeholder="ex. Doe" name="lastName" onChange={formik.handleChange} onFocus={onFieldFocused} value={formik.values.lastName} invalid={!!formik.errors.lastName} />
                  {!!formik.errors.lastName && <FormFeedback type="invalid">{formik.errors.lastName}</FormFeedback>}
                </Col>
              </Row>
              <Row className="mb-4">
                <Label className="col-sm-3 col-form-label">Title</Label>
                <Col sm={9}>
                  <Input type="text" className="form-control" placeholder="ex. Manager" name="title" onChange={formik.handleChange} onFocus={onFieldFocused} value={formik.values.title} invalid={!!formik.errors.title} />
                  {!!formik.errors.title && <FormFeedback type="invalid">{formik.errors.title}</FormFeedback>}
                </Col>
              </Row>
              <Row className="mb-4">
                <Label className="col-sm-3 col-form-label">Email *</Label>
                <Col sm={9}>
                  <Input type="text" className="form-control" placeholder="ex. john@domain.com" name="email" onChange={formik.handleChange} onFocus={onFieldFocused} value={formik.values.email} invalid={!!formik.errors.email} />
                  {!!formik.errors.email && <FormFeedback type="invalid">{formik.errors.email}</FormFeedback>}
                </Col>
              </Row>
              <Row className="mb-4">
                <Label className="col-sm-3 col-form-label">Phone *</Label>
                <Col sm={9}>
                  <Input type="text" className="form-control" placeholder="ex. 097 568 3578" name="phone" onChange={formik.handleChange} onFocus={onFieldFocused} value={formik.values.phone} invalid={!!formik.errors.phone} />
                  {!!formik.errors.phone && <FormFeedback type="invalid">{formik.errors.phone}</FormFeedback>}
                </Col>
              </Row>
              <Row className="mb-4">
                <Label className="col-sm-3 col-form-label">Timezone *</Label>
                <Col sm={9}>
                  <Select
                    classNamePrefix="select2-selection"
                    name="timezone"
                    options={getTimezoneOptions()}
                    onChange={selected => formik.setFieldValue('timezone', selected.value)}
                    onFocus={e => onFieldFocused(e, 'timezone')}
                    value={getTimezoneOptions().find(option => option.value === formik.values.timezone)}
                    className={!!formik.errors.timezone && 'is-invalid'} />
                  {!!formik.errors.timezone && <FormFeedback type="invalid">{formik.errors.timezone}</FormFeedback>}
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </CardBody>
    </Card>
  </React.Fragment>
}

UserForm.propTypes = {
  title: PropTypes.string,
  defaultValues: PropTypes.object,
  id: PropTypes.number,
  successRoute: PropTypes.string,
  cancelRoute: PropTypes.string,
  finishedHandler: PropTypes.func,
};

export default UserForm;