import React from "react"
import PropTypes from "prop-types"
import { Route, Navigate } from "react-router-dom"
import { route, routes } from "helpers/routeHelper"
import { useAuth } from "context/auth"

const Authmiddleware = ({
  component: Component,
  componentProps,
  layout: Layout,
  isProtected,
  isStrict,
}) => {

  const { isLoggedIn } = useAuth();

  if (isProtected && !isLoggedIn()) {
    // this route is protected and the user is not logged in
    // so redirect to login
    return <Navigate to={route(routes.login, null, { ret: encodeURIComponent(window.location.href) })} />
  } else if (!isProtected && isLoggedIn() && isStrict) {
    // this route is public and the user is logged in
    // so redirect to homepage
    return <Navigate to={route(routes.home)} />
  }
  return <Layout>
    <Component {...componentProps} />
  </Layout>
}

Authmiddleware.propTypes = {
  isProtected: PropTypes.bool,
  isStrict: PropTypes.bool,
  component: PropTypes.any,
  componentProps: PropTypes.object,
  layout: PropTypes.any,
}

export default Authmiddleware;
