import React, { useEffect, useState } from 'react'
import { Link, useParams } from "react-router-dom";
import { Row, Col, CardBody, Card, Container } from "reactstrap";
import { verifyEmail, doEmailVerificationCleanup } from "store/actions";
import { useDispatch, useSelector } from "react-redux";
import { route, routes } from "helpers/routeHelper";
import MetaTitle from 'components/Shared/MetaTitle';
import SpinnerChase from 'components/Shared/SpinnerChase';
import profile from "assets/images/profile-img.png";
import logo from "assets/images/logo-simple.svg";
import { BeException, EMAIL_ALREADY_VERIFIED, EMAIL_VERIFICATION_INVALID_TOKEN, USER_NOT_FOUND } from 'helpers/errorHelper';
import successIcon from "assets/images/check-round.svg";
import errorIcon from "assets/images/x-round.svg";

const VerifyEmail = () => {

  const { token } = useParams();

  // redux hook that dispatches actions
  const dispatch = useDispatch();

  /********** STATE **********/

  const { isVerificationInProgress, verified, verificationError } = useSelector(state => state.User.VerifyEmail);

  const [error, setError] = useState(null);

  /********** EFFECTS **********/

  // runs once on component mount
  useEffect(() => {
    dispatch(verifyEmail(token));
    return () => {
      dispatch(doEmailVerificationCleanup());
    }
  }, [token]);

  // runs whenever the 'verified' flag changes
  // which happens after an verify-email attempt
  useEffect(() => {
    if (verified === false) {
      let errMessage = 'Unable to verify email address';
      if (verificationError instanceof BeException) {
        switch (verificationError.code) {
          case EMAIL_VERIFICATION_INVALID_TOKEN:
            errMessage = 'This link is invalid or has expired';
            break;
          case USER_NOT_FOUND:
            errMessage = 'User account not found';
            break;
          case EMAIL_ALREADY_VERIFIED:
            errMessage = 'This email address is already verified';
            break;
        }
      }
      setError(errMessage);
    }
  }, [verified]);

  return <React.Fragment>
    <MetaTitle>Verify Email</MetaTitle>
    <div className="account-pages my-5 pt-sm-5">
      <Container>
        <Row className="justify-content-center">
          <Col md={8} lg={6} xl={5}>
            <Card className="overflow-hidden">
              <div className="bg-primary bg-soft">
                <Row>
                  <Col xs={7}>
                    <div className="text-primary p-4">
                      <h5 className="text-primary">Mavsign Verification</h5>
                      <p>Verify your account with a valid email address</p>
                    </div>
                  </Col>
                  <Col className="col-5 align-self-end">
                    <img src={profile} alt="" className="img-fluid" />
                  </Col>
                </Row>
              </div>
              <CardBody className="pt-0">
                <div>
                  <Link to={route(routes.home)} className="auth-logo-light">
                    <div className="avatar-md profile-user-wid mb-4">
                      <span className="avatar-title rounded-circle bg-light">
                        <img src={logo} height="44" />
                      </span>
                    </div>
                  </Link>
                </div>
                <div className="p-2">
                  {/* Visible when email verification request is in progress */}
                  {isVerificationInProgress && <SpinnerChase className="mt-1 mb-3" />}
                  {/* Visible when email verification has failed */}
                  {!!error && <div className="text-center">
                    <img src={errorIcon} />
                    <h4 className="mt-4 mb-3">Verification Failed</h4>
                    <p className="text-muted">{error}</p>
                  </div>}
                  {/* Visible when email verification is successfull */}
                  {verified && <div className="text-center">
                    <img src={successIcon} />
                    <h4 className="mt-4 mb-3">Verification Successful</h4>
                    <p className="text-muted">Your email address has been verified.<br />You can now login to Mavsign</p>
                    <Link to={route(routes.home)} className="btn btn-primary d-block">Login</Link>
                  </div>}
                </div>
              </CardBody>
            </Card>
            <div className="mt-5 text-center">
              <p>Already verified? <Link to={route(routes.login)} className="fw-medium text-primary">Login</Link></p>
              <p>© {new Date().getFullYear()} Maverick Signings</p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  </React.Fragment>
}

export default VerifyEmail;
