import React from "react"
import ExternalRedirect from "components/Shared/ExternalRedirect";
import { perms, useAccess } from "context/access";

const Dashboard = () => {

  const { getUserAppUrl } = useAccess();

  return <ExternalRedirect to={getUserAppUrl()} />
}

export default Dashboard;
