import React, { useEffect, useState } from "react";
import { Row, Col, CardBody, Card, Container, Form, Input, FormFeedback, Label, Button } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import MetaTitle from "components/Shared/MetaTitle";
import { doPasswordResetCleanup, requestPasswordReset } from "store/actions";
import profile from "assets/images/profile-img.png";
import logo from "assets/images/logo-simple.svg";
import { route, routes } from "helpers/routeHelper";
import { BeException, USER_NOT_FOUND, PASSWORD_RESET_USER_NOT_ACTIVE, ValidationException } from "helpers/errorHelper";
import successIcon from "assets/images/check-round.svg";

const LostPassword = () => {

  // redux hook that dispatches actions
  const dispatch = useDispatch();

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: {
      email: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().required('Please enter your email').email('Please enter a valid email address'),
    }),
    onSubmit: values => {
      setError(null);
      dispatch(requestPasswordReset(values));
    },
  });

  /********** STATE **********/

  const { isSendInProgress, sent, sendError } = useSelector(state => state.User.ResetPassword);

  const [error, setError] = useState(null);
  const [errCode, setErrCode] = useState();

  /********** EFFECTS **********/

  // runs once on component mount
  useEffect(() => {
    return () => {
      dispatch(doPasswordResetCleanup());
    }
  }, []);

  // runs whenever the 'sent' flag changes
  // which happens after a request-password-reset attempt
  useEffect(() => {
    if (sent === false) {
      formik.setSubmitting(false);
      // see if the action failed due to validation
      if (sendError instanceof ValidationException) {
        // show an error on each invalid field
        for (const [name, message] of Object.entries(sendError.fields)) {
          formik.setFieldError(name, message);
        }
        return;
      }
      let errMessage = 'Unable to send email';
      if (sendError instanceof BeException) {
        switch (sendError.code) {
          case USER_NOT_FOUND:
            errMessage = 'Email address not found';
            break;
          case PASSWORD_RESET_USER_NOT_ACTIVE:
            errMessage = 'Reset password unavailable';
            break;
        }
      }
      setError(errMessage);
      setErrCode(sendError.code)
    }
  }, [sent]);

  /********** EVENT HANDLERS **********/

  // focus event handler
  // used to clear field errors
  const onFieldFocused = (e, fieldName) => {
    const name = fieldName || e.target.name;
    formik.setFieldError(name, null);
  };

  const generateFailedContent = () => {
    if (error) {
      if (errCode === PASSWORD_RESET_USER_NOT_ACTIVE) {
        return <div className="text-center">
          <p className="text-muted mb-3">Reset password unavailable.</p>
          <Link to={route(routes.home)} className="btn btn-primary d-block">Login</Link>
        </div>
      }

      return <div className="text-center">
        <img src={successIcon} />
        <h4 className="mt-4 mb-3">Email Sent</h4>
        <p className="text-muted">If an account is associated with the specified email address, a password reset email will be sent shortly.</p>
        <Link to={route(routes.home)} className="btn btn-primary d-block">Login</Link>
      </div>
    }
  }

  return <React.Fragment>
    <MetaTitle>Forgot Password</MetaTitle>
    <div className="account-pages my-5 pt-sm-5">
      <Container>
        <Row className="justify-content-center">
          <Col md={8} lg={6} xl={5}>
            <Card className="overflow-hidden">
              <div className="bg-primary bg-soft">
                <Row>
                  <Col xs={7}>
                    <div className="text-primary p-4">
                      <h5 className="text-primary">Lost Password</h5>
                      <p>Enter your email address to reset your password.</p>
                    </div>
                  </Col>
                  <Col className="col-5 align-self-end">
                    <img src={profile} alt="" className="img-fluid" />
                  </Col>
                </Row>
              </div>
              <CardBody className="pt-0">
                <div>
                  <Link to={route(routes.home)} className="auth-logo-light">
                    <div className="avatar-md profile-user-wid mb-4">
                      <span className="avatar-title rounded-circle bg-light">
                        <img src={logo} height="44" />
                      </span>
                    </div>
                  </Link>
                </div>
                <div className="p-2">
                  {/* Visible when email sending is successfull */}
                  {sent && <div className="text-center">
                    <img src={successIcon} />
                    <h4 className="mt-4 mb-3">Email Sent</h4>
                    <p className="text-muted">Instructions to reset your password have been sent to the specified email address</p>
                    <Link to={route(routes.home)} className="btn btn-primary d-block">Login</Link>
                  </div>}

                  {/* Visible when email sending is not complete */}
                  {!sent && <Form
                    noValidate
                    className="form-horizontal"
                    onSubmit={formik.handleSubmit}>

                    {/* Visible when email sending has failed */}
                    {generateFailedContent()}

                    {!error && <>
                      <div className="mb-3">
                        <Label className="form-label">Email</Label>
                        <Input
                          type="email"
                          name="email"
                          className="form-control"
                          placeholder="Enter email address"
                          onChange={formik.handleChange}
                          onFocus={onFieldFocused}
                          onBlur={formik.handleBlur}
                          value={formik.values.email}
                          invalid={!!formik.errors.email} />
                        {!!formik.errors.email && <FormFeedback type="invalid">{formik.errors.email}</FormFeedback>}
                      </div>

                      <div className="mt-3 d-grid">
                        <Button type="submit" color="primary" className="btn-block" disabled={isSendInProgress}>
                          {/* Visible when email sending request is in progress */}
                          {isSendInProgress && <i className="mdi mdi-spin mdi-loading me-2" />}
                          Send email
                        </Button>
                      </div>

                      <div className="mt-4 text-center">
                        <Link to={route(routes.home)} className="text-muted">
                          <i className="mdi mdi-home me-1" />Back to sign in
                        </Link>
                      </div>
                    </>}
                  </Form>}
                </div>
              </CardBody>
            </Card>
            {/*<div className="mt-5 text-center">
              <p>Don&apos;t have an account ? <a href="https://mavsign.com" target="_blank" rel="noreferrer" className="fw-medium text-primary">Sign up now</a></p>
              <p>© {new Date().getFullYear()} Maverick Signings</p>
            </div>*/}
          </Col>
        </Row>
      </Container>
    </div>
  </React.Fragment>
};

export default LostPassword;