import { route, routes } from "helpers/routeHelper";
import Dashboard from "pages/Dashboard/index";
import Login from "pages/Authentication/Login";
import UserProfile from "pages/User/Profile";
import Logout from "pages/Authentication/Logout";
import UserPassword from "pages/User/Password";
import LostPassword from "pages/User/LostPassword";
import ResetPassword from "pages/User/ResetPassword";
import ActivateAccount from "pages/User/ActivateAccount";
import VerifyEmail from "pages/User/VerifyEmail";
import TwoFactorAuth from "pages/Authentication/TwoFactorAuth";

const publicRoutes = [
  { path: route(routes.login), component: Login, isStrict: true },
  { path: route(routes.lost_password), component: LostPassword, isStrict: true },
  { path: route(routes.reset_password), component: ResetPassword, isStrict: true },
  { path: route(routes.activate_account), component: ActivateAccount },
  { path: route(routes.verify_email), component: VerifyEmail },
  { path: route(routes.two_factor_auth), component: TwoFactorAuth },
];

const protectedRoutes = [
  { path: route(routes.home), component: Dashboard },
  { path: route(routes.view_me), component: UserProfile },
  { path: route(routes.change_my_password), component: UserPassword },
  { path: route(routes.logout), component: Logout },
];

export { publicRoutes, protectedRoutes }
