export class BeException extends Error {

  constructor(message, code, prev) {
    super(message);
    Error.captureStackTrace(this, this.constructor);
    this.name = this.constructor.name;
    this.code = code;
    this.prev = prev;
  }
}

export class AuthException extends BeException {

  constructor(message, code, prev) {
    super(message || 'Access denied', code, prev);
  }
}

export class AuthLockedException extends AuthException {

  constructor(message, code, lockedUntil) {
    super(message, code);
    this.lockedUntil = lockedUntil;
  }
}

export class AccessDeniedException extends BeException {

  constructor(message, code, prev) {
    super(message || 'Access denied', code, prev);
  }
}

export class NotFoundException extends BeException {

  constructor(message, code, prev) {
    super(message || 'Resource not found', code, prev);
  }
}

export class BadRequestException extends BeException {

  constructor(message, code, prev) {
    super(message || 'Bad request', code, prev);
  }
}

export class ValidationException extends BadRequestException {

  constructor(message, fields) {
    super(message);
    this.fields = fields;
  }
}

export class ServerErrorException extends BeException {

  constructor(message, code, prev) {
    super(message || 'Internal server error', code, prev);
  }
}

export class LocalException extends Error {

  constructor(message, code, prev) {
    super(message);
    Error.captureStackTrace(this, this.constructor);
    this.name = this.constructor.name;
    this.code = code;
    this.prev = prev;
  }
}

export class NetworkErrorException extends LocalException {

  constructor(message, code, prev) {
    super(message || 'Network error', code, prev);
  }
}

export const ACC_TOKEN_MISSING = 1000;
export const ACC_TOKEN_JWT_EXPIRED = 1002;
export const LOGIN_USER_NOT_FOUND = 1011;
export const LOGIN_INVALID_CREDENTIALS = 1012;
export const USER_NOT_FOUND = 1030;
export const EMAIL_VERIFICATION_INVALID_TOKEN = 1069;
export const PASSWORD_RESET_INVALID_TOKEN = 1070;
export const EMAIL_ALREADY_VERIFIED = 1078;
export const ACCOUNT_ALREADY_ACTIVATED = 1079;
export const MAX_FAILED_AUTH_ATTEMPTS_REACHED = 1116;
export const TFA_TOKEN_INVALID_CODE = 1122;
export const TFA_TOKEN_EXPIRED_CODE = 1123;
export const TFA_TOKEN_RESEND_ABUSE = 1124;
export const PASSWORD_RESET_CANNOT_USE_SAME_PASSWORD = 1130;
export const LOGIN_USER_NOT_ACTIVATED = 1142;
export const PASSWORD_RESET_USER_NOT_ACTIVE = 1317;