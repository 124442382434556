import { toUserModel } from "helpers/utilHelper";
import { combineReducers } from "redux";
import {
  GET_AUTH_USER_OK,
  GET_AUTH_USER_ERR,
  LOGIN_USER,
  LOGIN_USER_OK,
  LOGIN_USER_ERR,
  SAVE_RETURN_URL,
  DO_LOGIN_USER_CLEANUP,
  LOGOUT_USER,
  LOGOUT_USER_OK,
  LOGOUT_USER_ERR,
  REFRESH_AUTH_USER,
  REFRESH_AUTH_USER_OK,
  REFRESH_AUTH_USER_ERR,
  VALIDATE_VERIF_CODE,
  VALIDATE_VERIF_CODE_OK,
  VALIDATE_VERIF_CODE_ERR,
  RESEND_VERIF_CODE,
  RESEND_VERIF_CODE_OK,
  RESEND_VERIF_CODE_ERR,
  DO_VERIF_CODE_CLEANUP,
} from "./actionTypes"

/********** LOGIN **********/

const defaultLoginState = {
  user: null,
  userError: null,
  isAuth: null,
  loggedIn: null,
  loginError: null,
  isLoginInProgress: false,
  returnUrl: null,
  loggedOut: null,
  logoutError: null,
  loggedOutUser: null,
  hasTfa: false,
  tfaLoggedIn: null,
  tfaLoginError: null,
  isTfaLoginInProgress: false,
  codeResent: null,
  codeResendError: null,
  isCodeResendInProgress: false,
  hasPasswdChange: false,
  passwdChangeToken: null,
}

const Login = (state = defaultLoginState, action) => {
  switch (action.type) {
    case GET_AUTH_USER_OK:
      state = {
        ...state,
        isAuth: true,
        user: toUserModel(action.payload.response.user),
      };
      break
    case GET_AUTH_USER_ERR:
      state = {
        ...state,
        isAuth: false,
        userError: action.payload.error,
      };
      break
    case LOGIN_USER:
      state = {
        ...state,
        loggedIn: null,
        loginError: null,
        isLoginInProgress: true,
      };
      break
    case LOGIN_USER_OK:
      state = {
        ...state,
        loggedIn: true,
        isLoginInProgress: false,
        hasTfa: action.payload.response.tfa,
        hasPasswdChange: action.payload.response.passwdChange,
        passwdChangeToken: action.payload.response.token,
      };
      break
    case LOGIN_USER_ERR:
      state = {
        ...state,
        loggedIn: false,
        loginError: action.payload.error,
        isLoginInProgress: false,
      };
      break
    case SAVE_RETURN_URL:
      state = {
        ...state,
        returnUrl: action.payload.url,
      };
      break
    case DO_LOGIN_USER_CLEANUP:
      state = {
        ...state,
        // some state vars cannot be reset because they are needed in other subsequent login screens
        // for example with 2fa
        hasTfa: false,
        hasPasswdChange: false,
        passwdChangeToken: null,
      };
      break
    case LOGOUT_USER:
      state = {
        ...state,
        loggedOut: null,
        logoutError: null,
        loggedOutUser: action.payload.user,
      };
      break
    case LOGOUT_USER_OK:
      state = {
        ...state,
        user: null,
        // trigger an auth re-check to detect the new user in case of an impersonation logout
        isAuth: null,
        loggedOut: true,
      };
      break
    case LOGOUT_USER_ERR:
      state = {
        ...state,
        loggedOut: false,
        logoutError: action.payload.error,
      };
      break
    case REFRESH_AUTH_USER_OK:
      state = {
        ...state,
        user: toUserModel(action.payload.response.user),
      };
      break
    case VALIDATE_VERIF_CODE:
      state = {
        ...state,
        tfaLoggedIn: null,
        tfaLoginError: null,
        isTfaLoginInProgress: true,
      };
      break
    case VALIDATE_VERIF_CODE_OK:
      state = {
        ...state,
        tfaLoggedIn: true,
        isTfaLoginInProgress: false,
        hasPasswdChange: action.payload.response.passwdChange,
        passwdChangeToken: action.payload.response.token,
      };
      break
    case VALIDATE_VERIF_CODE_ERR:
      state = {
        ...state,
        tfaLoggedIn: false,
        tfaLoginError: action.payload.error,
        isTfaLoginInProgress: false,
      };
      break
    case RESEND_VERIF_CODE:
      state = {
        ...state,
        codeResent: null,
        codeResendError: null,
        isCodeResendInProgress: true,
      };
      break
    case RESEND_VERIF_CODE_OK:
      state = {
        ...state,
        codeResent: true,
        isCodeResendInProgress: false,
      };
      break
    case RESEND_VERIF_CODE_ERR:
      state = {
        ...state,
        codeResent: false,
        codeResendError: action.payload.error,
        isCodeResendInProgress: false,
      };
      break
    case DO_VERIF_CODE_CLEANUP:
      state = {
        ...state,
        hasPasswdChange: false,
        passwdChangeToken: null,
      };
      break
  }
  return state;
}

export default combineReducers({
  Login,
})
