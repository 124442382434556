import React, { useEffect } from "react"
import { logoutUser } from "store/actions"
import { useDispatch, useSelector } from "react-redux"
import Error from "pages/Error"
import { route, routes } from "helpers/routeHelper"
import { perms, useAccess } from "context/access"
import { useAuth } from "context/auth"
import ExternalRedirect from "components/Shared/ExternalRedirect"

const Logout = () => {

  const dispatch = useDispatch();
  const { user } = useAuth();
  const { getUserAppUrl } = useAccess();
  const loggedOut = useSelector(state => state.Auth.Login.loggedOut);
  const logoutError = useSelector(state => state.Auth.Login.logoutError);
  const loggedOutUser = useSelector(state => state.Auth.Login.loggedOutUser);

  useEffect(() => {
    // check if no logout has been attempted yet
    // this is important especially in case of impersonation logout
    // because when logging out of impersonation one still remains authenticated (with a different account)
    // therefore the next auth re-check will find a valid authenticated user
    // and will not redirect to login but to the same route (logout)
    // so without this check the user would be logged out over and over again
    // essentially performing a complete logout instead of a one-step logout
    if (loggedOut === null) {
      // this will fire an api call
      // which, if successfull, will update the auth state (isAuth: unknown)
      // which in turn will trigger an auth re-check and context change
      // which will then attempt to re-render the same route (logout)
      // which, because it is protected, will redirect to login (for a regular logout)
      // or redirect back here (for an impersonation logout) and stop at the check above
      dispatch(logoutUser(user));
    }
  }, [loggedOut]);

  // the redirect below will only be reached when logging out from impersonation
  // with a regular logout, the router will redirect to login before reaching this point
  return <React.Fragment>
    {loggedOut && <ExternalRedirect to={getUserAppUrl(route(routes.view_user, loggedOutUser.id))} />}
    {logoutError && <Error error={logoutError} />}
  </React.Fragment>
}

export default Logout;
