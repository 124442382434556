import { LocalException } from './errorHelper';
import { isObject, valueIsEmpty } from './utilHelper';

export const routes = {
  home: '/',
  login: '/login',
  logout: '/logout',
  register: '/register',
  lost_password: '/lost-password',
  reset_password: '/reset-password/:token',
  two_factor_auth: '/2fa',
  view_me: '/me',
  change_my_password: '/me/password',
  view_user: '/user/:id',
  activate_account: '/activate-account/:token',
  verify_email: '/verify-email/:token',
};

export const route = (route, params, query) => {
  if (!Object.values(routes).includes(route)) {
    throw new LocalException(`Unknown route '${route}'`);
  }
  if (!valueIsEmpty(params)) {
    if (Array.isArray(params)) {
      params.forEach(param => {
        // handle optional params
        if (valueIsEmpty(param)) {
          route = route.replace(new RegExp('/:([a-zA-Z]+)'), '');
        } else {
          route = route.replace(new RegExp(':([a-zA-Z]+)'), param);
        }
      });
    } else if (isObject(params)) {
      for (let key in params) {
        // handle optional params
        if (valueIsEmpty(params[key])) {
          route = route.replace(new RegExp(`/:${key}`), '');
        } else {
          route = route.replace(new RegExp(`:${key}`), params[key]);
        }
      }
    } else {
      // handle optional params
      if (valueIsEmpty(params)) {
        route = route.replace(new RegExp('/:([a-zA-Z]+)'), '');
      } else {
        route = route.replace(new RegExp(':([a-zA-Z]+)'), params);
      }
    }
  }
  if (query) {
    if (isObject(query)) {
      let parts = [];
      for (let key in query) {
        if (Array.isArray(query[key])) {
          query[key].forEach(value => {
            parts.push(`${key}[]=${value}`);
          });
        } else if (isObject(query[key])) {
          for (let key2 in query[key]) {
            parts.push(`${key}[${key2}]=${query[key][key2]}`);
          }
        } else {
          parts.push(`${key}=${query[key]}`);
        }
      }
      route += `?${parts.join('&')}`;
    } else {
      route += `?${query}`;
    }
  }
  return route;
};