import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from "classnames";
import { getBeUrl } from 'helpers/utilHelper';

const UserAvatar = props => {

  const { id, image, initials, size, className, icon } = props;

  const [isImageError, setIsImageError] = useState(false);

  const imageUrl = getBeUrl(`user/${id}/${image}`);

  const canShowImage = () => !!id && !!image && !isImageError;

  return <div className={classnames('user-avatar', `user-avatar-${size}`, className)}>
    {canShowImage() && <img src={imageUrl} className="user-avatar-img" onError={() => setIsImageError(true)} />}
    {!canShowImage() && <div className="user-avatar-noimg bg-primary bg-soft text-primary">
      {initials && <span className="user-avatar-initials">{initials}</span>}
      {!initials && <i className={classnames('user-avatar-icon', { [icon]: !!icon, 'fas fa-user': !icon })}></i>}
    </div>}
  </div>
}

UserAvatar.propTypes = {
  id: PropTypes.number,
  image: PropTypes.string,
  initials: PropTypes.string,
  size: PropTypes.string.isRequired,
  className: PropTypes.string,
  icon: PropTypes.string,
}

export default UserAvatar;