import moment from 'moment-timezone';

export const formats = {
  DATE: 'MMM D',
  DATETIME: 'MMM D, h:mm a',
}

export const initTimezone = zone => moment.tz.setDefault(zone);

export const formatDate = (date, format) => moment(date).format(format);

export const formatUtcDate = (date, format) => moment.utc(date).format(format);

export const formatTimestamp = (timestamp, format) => moment.unix(timestamp).format(format);

export const timestamp = date => Math.floor((date || Date.now()) / 1000);

export const dateFromTimestamp = timestamp => new Date(timestamp * 1000);

export const formatTimeInterval = seconds => {

  let interval = seconds / 31536000;

  if (interval > 1) {
    if (interval < 2) return "1 year";
    return Math.floor(interval) + " years";
  }

  interval = seconds / 2592000;

  if (interval > 1) {
    if (interval < 2) return "1 month";
    return Math.floor(interval) + " months";
  }

  interval = seconds / 86400;

  if (interval > 1) {
    if (interval < 2) return "1 day";
    return Math.floor(interval) + " days";
  }

  interval = seconds / 3600;

  if (interval > 1) {
    if (interval < 2) return "1 hour";
    return Math.floor(interval) + " hours";
  }

  interval = seconds / 60;

  if (interval > 1) {
    if (interval < 2) return "1 minute";
    return Math.floor(interval) + " minutes";
  }

  return Math.floor(seconds) === 0 ? "now" : (Math.floor(seconds) === 1 ? "1 second" : Math.floor(seconds) + " seconds");
}