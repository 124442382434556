import React, { useState } from "react";
import PropTypes from "prop-types";
import { Input, InputGroup, FormFeedback } from "reactstrap";
import classnames from "classnames";

const PasswordInput = ({ id, errors, noFill, ...rest }) => {

  const [passwordVisible, setPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => setPasswordVisible(current => !current);

  // for some password inputs we want to disable Google autofill as it doesn't get validated in all browsers
  // issue: https://stackoverflow.com/questions/55244590/autofill-does-not-trigger-onchange
  // as a cross-browser solution, we are setting the input as `readonly` at first to prevent the autofill
  // on field focused, we remove the attribute so we can edit
  const onReadOnlyFieldFocused = () => {
    document.getElementById(id).removeAttribute("readonly");
  }

  return <InputGroup>
    <Input
      id={id}
      type={passwordVisible ? "text" : "password"}
      className={classnames("form-control password-input", { "no-fill": noFill })}
      invalid={!!errors}
      readOnly={noFill}
      onFocus={noFill && onReadOnlyFieldFocused}
      {...rest} />
    {!!errors && <FormFeedback type="invalid">{errors}</FormFeedback>}
    <div className="show-password" onClick={togglePasswordVisibility}>
      <i className={classnames("show-password-icon mdi", passwordVisible ? "mdi-eye" : "mdi-eye-off", { "is-invalid": !!errors })} />
    </div>
  </InputGroup>
};

PasswordInput.propTypes = {
  id: PropTypes.string,
  errors: PropTypes.object,
  noFill: PropTypes.bool,
};

export default PasswordInput;