import { takeEvery, put, call } from "redux-saga/effects";

import {
  GET_AUTH_USER,
  LOGIN_USER,
  LOGOUT_USER,
  REFRESH_AUTH_USER,
  VALIDATE_VERIF_CODE,
  RESEND_VERIF_CODE,
} from "./actionTypes";

import {
  getAuthUserOk,
  getAuthUserErr,
  loginUserOk,
  loginUserErr,
  logoutUserOk,
  logoutUserErr,
  refreshAuthUserOk,
  refreshAuthUserErr,
  validateVerifCodeOk,
  validateVerifCodeErr,
  resendVerifCodeOk,
  resendVerifCodeErr,
} from "./actions";

import {
  getAuthUser,
  loginUser,
  logoutUser,
  validateVerifCode,
  resendVerifCode,
} from "helpers/backendHelper";

function* onGetAuthUser() {
  try {
    const response = yield call(getAuthUser);
    yield put(getAuthUserOk(response));
  } catch (error) {
    yield put(getAuthUserErr(error));
  }
}

function* onRefreshAuthUser() {
  try {
    const response = yield call(getAuthUser);
    yield put(refreshAuthUserOk(response));
  } catch (error) {
    yield put(refreshAuthUserErr(error));
  }
}

function* onLoginUser({ payload: { data } }) {
  try {
    const response = yield call(loginUser, data);
    yield put(loginUserOk(response));
  } catch (error) {
    yield put(loginUserErr(error));
  }
}

function* onLogoutUser() {
  try {
    yield call(logoutUser);
    yield put(logoutUserOk());
  } catch (error) {
    yield put(logoutUserErr(error));
  }
}

function* onValidateVerifCode({ payload: { data } }) {
  try {
    const response = yield call(validateVerifCode, data);
    yield put(validateVerifCodeOk(response));
  } catch (error) {
    yield put(validateVerifCodeErr(error));
  }
}

function* onResendVerifCode() {
  try {
    const response = yield call(resendVerifCode);
    yield put(resendVerifCodeOk(response));
  } catch (error) {
    yield put(resendVerifCodeErr(error));
  }
}

function* authSaga() {
  yield takeEvery(GET_AUTH_USER, onGetAuthUser);
  yield takeEvery(REFRESH_AUTH_USER, onRefreshAuthUser);
  yield takeEvery(LOGIN_USER, onLoginUser);
  yield takeEvery(LOGOUT_USER, onLogoutUser);
  yield takeEvery(VALIDATE_VERIF_CODE, onValidateVerifCode);
  yield takeEvery(RESEND_VERIF_CODE, onResendVerifCode);
}

export default authSaga;
