import User from 'model/user';
import { Store } from 'react-notifications-component';
import moment from "moment-timezone";
import config from 'config';
import UserRole from 'model/userRole';
import randomBytes from 'randombytes';
import DOMPurify from 'dompurify';
import regx from 'constants/regx';

export const sleep = time => new Promise((resolve) => setTimeout(resolve, time));

export const getSharedPaginationOptions = () => ({
  custom: true,
  hidePageListOnlyOnePage: true,
  paginationSize: 10,
  sizePerPageList: [
    { text: '1', value: 1 },
    { text: '50', value: 50 },
    { text: '100', value: 100 },
    { text: '200', value: 200 },
  ],
});

export const getSharedTableOptions = () => ({
  keyField: "id",
  bordered: false,
  striped: false,
  defaultSortDirection: "asc",
  selectRow: {
    mode: 'radio',
    hideSelectColumn: true,
  },
  classes: "table align-middle table-nowrap",
  headerWrapperClasses: "thead-light",
  responsive: true,
  remote: true,
});

export const valueIsDefined = value => value !== undefined && value !== null;

export const valueIsEmpty = value => {
  if (typeof value === 'string') {
    value = value.trim();
  }
  // allow 0 and FALSE
  return value !== 0 && value !== false && !value;
}

export const mapNonEmpty = obj => Object.entries(obj).reduce((a, [k, v]) => (!valueIsEmpty(v) ? (a[k] = v, a) : a), {});

export const hasNonEmpty = obj => Object.keys(mapNonEmpty(obj)).length > 0;

// axios serializes only plain objects
// so any nested objects will end up as json
// but we need the filters as a query string array
// so here we do the transformation
export const flattenFilters = params => {
  // clone the object to avoid changing the original
  const newParams = { ...params };
  if (newParams.filters) {
    // loop through all filters
    for (const [key, value] of Object.entries(newParams.filters)) {
      // if the filter value is not empty
      if (!valueIsEmpty(value)) {
        // add the filter under the root object
        // change the key so it will be decoded properly on the backend
        newParams[`filters[${key}]`] = value;
      }
    }
    // remove the filters key since it is no longer needed
    delete newParams.filters;
  }
  return newParams;
}

// the Select control expects a list of {value, label} objects as options
// most of the time api response is different than what the Select control expects
// so here we do the transformation
export const toSelectOptions = (list, valueProp = 'id', labelProp = 'name') => {
  return list.map(item => ({
    label: item[labelProp],
    value: item[valueProp],
  }));
}

export const getYesNoOptions = (includeAll = false) => {
  const options = [{
    label: "Yes",
    value: 1,
  }, {
    label: "No",
    value: 0
  }];
  if (includeAll) {
    options.unshift({
      label: "All",
      value: '',
    });
  }
  return options;
}

export const isObject = obj => typeof obj === 'object' && obj !== null;

export const showNotification = (type, message, options) => {
  const defaultOptions = {
    title: '',
    insert: 'top',
    container: 'top-center',
    animationIn: ['animate__animated', 'animate__fadeIn'],
    animationOut: ['animate__animated', 'animate__fadeOut'],
    dismiss: {
      duration: 6000,
      onScreen: true,
      pauseOnHover: true,
      showIcon: true,
    },
    width: 400,
  }
  return Store.addNotification({
    ...defaultOptions,
    ...options,
    type: type,
    message: message,
  });
}

export const showBriefNotification = (type, message, options) => {
  const defaultOptions = {
    title: '',
    insert: 'top',
    container: 'top-center',
    animationIn: ['animate__animated', 'animate__fadeIn'],
    animationOut: ['animate__animated', 'animate__fadeOut'],
    dismiss: {
      duration: 2000,
      onScreen: true,
      pauseOnHover: true,
      showIcon: true,
    },
    width: 400,
  }
  return Store.addNotification({
    ...defaultOptions,
    ...options,
    type: type,
    message: message,
  });
}

export const showSuccess = (message, options) => showNotification('success', message, options);

export const showBriefSuccess = (message, options) => showBriefNotification('success', message, options);

export const showMessage = (message, options) => showNotification('info', message, options);

export const showWarning = (message, options) => showNotification('warning', message, options);

export const showError = (message, options) => showNotification('danger', message, options);

export const showBriefError = (message, options) => showBriefNotification('danger', message, options);

export const removeNotification = id => Store.removeNotification(id);

export const getChangeEventData = (e, meta) => {
  let name, value;
  if (e.target) {
    name = e.target.name;
    value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
  } else {
    // meta is only available for Select
    name = meta.name;
    // the Select component only accepts an object as value
    // so here we return the entire selected option
    value = e;
  }
  return [name, value];
}

export const trim = (str, char) => {
  char = char.replace(/[-/^$*+?.()|[]{}]/g, '$&');
  return str.replace(new RegExp(
    "^[" + char + "]+|[" + char + "]+$", "g"
  ), "");
}

export const ltrim = (s, c) => {
  c = c.replace(/[-/\^$*+?.()|[]{}]/g, '\$&');
  return s.replace(new RegExp(
    "^[" + c + "]+", "g"
  ), "");
}

export const rtrim = (s, c) => {
  c = c.replace(/[-/\^$*+?.()|[]{}]/g, '\$&');
  return s.replace(new RegExp(
    "[" + c + "]+$", "g"
  ), "");
}

export const buildUrl = (base, fragment) => rtrim(base, '/') + (fragment ? '/' + ltrim(fragment, '/') : '');

export const getBeUrl = fragment => buildUrl(config.API_BE_URL, fragment);

export const getAdminAppUrl = fragment => buildUrl(config.ADMIN_URL, fragment);

export const getDealerAppUrl = fragment => buildUrl(config.DEALER_URL, fragment);

export const getNotaryAppUrl = fragment => buildUrl(config.NOTARY_URL, fragment);

export const nullsToEmptyStrings = obj => {
  if (!obj) {
    return;
  }
  if (Array.isArray(obj)) {
    return obj.map(v => v === null ? '' : v);
  } else {
    return Object.fromEntries(Object.entries(obj).map(([k, v]) => [k, v === null ? '' : v]));
  }
}

export const toUserModel = user => Object.assign(new User(), user);

export const getTimezoneOptions = () => moment.tz.zonesForCountry('US').map(zone => ({
  label: `${zone} (${moment.tz(zone).zoneAbbr()})`,
  value: zone,
}));

export const getTimezoneAbbr = zone => moment.tz(zone).zoneAbbr();

export const getUserOwner = user => {
  const owner = {
    name: null,
    id: null,
    route: null,
    type: null,
  };
  if (UserRole.isAdminType(user.userRoleId)) {
    owner.name = config.APP_TITLE;
    owner.type = 'mav';
  } else if (UserRole.isDealerGroupManager(user.userRoleId)) {
    owner.name = user.dealerGroupName;
    owner.id = user.dealerGroupId;
    owner.route = 'view_dealer_group';
    owner.type = 'dealerGroup';
  } else if (UserRole.isDealerRegionalManager(user.userRoleId)) {
    owner.name = user.dealerRegionName;
    owner.id = user.dealerRegionId;
    owner.route = 'view_dealer_region';
    owner.type = 'dealerRegion';
    owner.parentName = user.dealerGroupName;
    owner.parentId = user.dealerGroupId;
    owner.parentRoute = 'view_dealer_group';
  } else if (UserRole.isDealerType(user.userRoleId)) {
    owner.name = user.dealerStoreName;
    owner.id = user.dealerStoreId;
    owner.route = 'view_dealer_store';
    owner.type = 'dealerStore';
  }
  return owner;
}

export const withAuth = fragment => buildUrl(config.AUTH_PATH_PREFIX, fragment);

export const randomString = len => new Promise((resolve, reject) => {
  randomBytes(len, function (ex, buffer) {
    if (ex) {
      reject(null);
    }
    resolve(buffer.toString('hex'));
  });
});

export const mergeRefs = (...refs) => {
  const filteredRefs = refs.filter(Boolean);
  if (!filteredRefs.length) return null;
  if (filteredRefs.length === 0) return filteredRefs[0];
  return inst => {
    for (const ref of filteredRefs) {
      if (typeof ref === 'function') {
        ref(inst);
      } else if (ref) {
        ref.current = inst;
      }
    }
  };
};

export const dictionaryToSelectOptions = dict => Object.entries(dict).map(entry => ({ label: entry[1], value: entry[0] }));

export const formatPhone = phone => {
  if (!phone) {
    return;
  }
  const clean = phone.replace(/\D/g, '');
  const match = clean.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (!match) {
    return clean;
  }
  let result = '';
  if (!!match[1]) {
    result += match[1];
  }
  if (!!match[2]) {
    result += '-' + match[2];
  }
  if (!!match[3]) {
    result += '-' + match[3];
  }
  return result;
}

export const sanitizeHtml = html => DOMPurify.sanitize(html);

export const stripTags = value => value.replace(/(<([^>]+)>)/gi, "");

export const getInitialsFromName = name => name.match(/\b(\w)/g).join('');

export const filterObjectKeys = (obj, keys) => {
  if (!obj) {
    return null;
  }
  return Object.fromEntries(Object.entries(obj).filter(([key]) => keys.includes(key)));
}

export const phoneHasNoOfDigits = (value) => {
  return !!value && value.replace(regx.phoneChars, '').length == config.PHONE_NR_OF_DIGITS;
}