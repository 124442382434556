import {
  GET_USER,
  GET_USER_OK,
  GET_USER_ERR,
  DO_USER_SINGLE_CLEANUP,
  SAVE_AUTH_USER,
  SAVE_USER_OK,
  SAVE_USER_ERR,
  DO_USER_FORM_CLEANUP,
  SAVE_AUTH_USER_PWD,
  UPLOAD_AUTH_USER_IMG,
  DELETE_AUTH_USER_IMG,
  DO_AUTH_USER_IMG_CLEANUP,
  UPLOAD_AUTH_USER_IMG_OK,
  UPLOAD_AUTH_USER_IMG_ERR,
  DELETE_AUTH_USER_IMG_OK,
  DELETE_AUTH_USER_IMG_ERR,
  REQUEST_PASSWORD_RESET,
  REQUEST_PASSWORD_RESET_OK,
  REQUEST_PASSWORD_RESET_ERR,
  RESET_PASSWORD,
  RESET_PASSWORD_OK,
  RESET_PASSWORD_ERR,
  DO_PASSWORD_RESET_CLEANUP,
  ACTIVATE_ACCOUNT,
  ACTIVATE_ACCOUNT_OK,
  ACTIVATE_ACCOUNT_ERR,
  DO_ACCOUNT_ACTIVATION_CLEANUP,
  VERIFY_EMAIL,
  VERIFY_EMAIL_OK,
  VERIFY_EMAIL_ERR,
  DO_EMAIL_VERIFICATION_CLEANUP,
  PRE_ACTIVATE_ACCOUNT,
  PRE_ACTIVATE_ACCOUNT_OK,
  PRE_ACTIVATE_ACCOUNT_ERR,
  PRE_RESET_PASSWORD,
  PRE_RESET_PASSWORD_OK,
  PRE_RESET_PASSWORD_ERR,
  DO_JWT_VALIDATION_CLEANUP,
} from "./actionTypes";

/********** SINGLE **********/

export const getUser = id => ({
  type: GET_USER,
  payload: { id },
});

export const getUserOk = response => ({
  type: GET_USER_OK,
  payload: { response },
});

export const getUserErr = error => ({
  type: GET_USER_ERR,
  payload: { error },
});

export const doUserSingleCleanup = () => ({
  type: DO_USER_SINGLE_CLEANUP,
});

/********** FORM **********/

export const saveAuthUser = data => ({
  type: SAVE_AUTH_USER,
  payload: { data }
});

export const saveAuthUserPwd = data => ({
  type: SAVE_AUTH_USER_PWD,
  payload: { data }
});

export const saveUserOk = response => ({
  type: SAVE_USER_OK,
  payload: { response },
});

export const saveUserErr = error => ({
  type: SAVE_USER_ERR,
  payload: { error },
});

export const doUserFormCleanup = () => ({
  type: DO_USER_FORM_CLEANUP,
});

/********** IMAGE **********/

export const uploadAuthUserImg = data => ({
  type: UPLOAD_AUTH_USER_IMG,
  payload: { data },
});

export const uploadAuthUserImgOk = response => ({
  type: UPLOAD_AUTH_USER_IMG_OK,
  payload: { response },
});

export const uploadAuthUserImgErr = error => ({
  type: UPLOAD_AUTH_USER_IMG_ERR,
  payload: { error },
});

export const deleteAuthUserImg = () => ({
  type: DELETE_AUTH_USER_IMG,
});

export const deleteAuthUserImgOk = response => ({
  type: DELETE_AUTH_USER_IMG_OK,
  payload: { response },
});

export const deleteAuthUserImgErr = error => ({
  type: DELETE_AUTH_USER_IMG_ERR,
  payload: { error },
});

export const doAuthUserImgCleanup = () => ({
  type: DO_AUTH_USER_IMG_CLEANUP,
});

/********** VALIDATE JWT **********/

export const preActivateAccount = token => ({
  type: PRE_ACTIVATE_ACCOUNT,
  payload: { token },
});

export const preActivateAccountOk = response => ({
  type: PRE_ACTIVATE_ACCOUNT_OK,
  payload: { response },
});

export const preActivateAccountErr = error => ({
  type: PRE_ACTIVATE_ACCOUNT_ERR,
  payload: { error },
});

export const preResetPassword = token => ({
  type: PRE_RESET_PASSWORD,
  payload: { token },
});

export const preResetPasswordOk = response => ({
  type: PRE_RESET_PASSWORD_OK,
  payload: { response },
});

export const preResetPasswordErr = error => ({
  type: PRE_RESET_PASSWORD_ERR,
  payload: { error },
});

export const doJwtValidationCleanup = () => ({
  type: DO_JWT_VALIDATION_CLEANUP,
});

/********** RESET PASSWORD **********/

export const requestPasswordReset = data => ({
  type: REQUEST_PASSWORD_RESET,
  payload: { data },
});

export const requestPasswordResetOk = response => ({
  type: REQUEST_PASSWORD_RESET_OK,
  payload: { response },
});

export const requestPasswordResetErr = error => ({
  type: REQUEST_PASSWORD_RESET_ERR,
  payload: { error },
});

export const resetPassword = (token, data) => ({
  type: RESET_PASSWORD,
  payload: { token, data },
});

export const resetPasswordOk = response => ({
  type: RESET_PASSWORD_OK,
  payload: { response },
});

export const resetPasswordErr = error => ({
  type: RESET_PASSWORD_ERR,
  payload: { error },
});

export const doPasswordResetCleanup = () => ({
  type: DO_PASSWORD_RESET_CLEANUP,
});

/********** ACTIVATE ACCOUNT **********/

export const activateAccount = (token, data) => ({
  type: ACTIVATE_ACCOUNT,
  payload: { token, data },
});

export const activateAccountOk = response => ({
  type: ACTIVATE_ACCOUNT_OK,
  payload: { response },
});

export const activateAccountErr = error => ({
  type: ACTIVATE_ACCOUNT_ERR,
  payload: { error },
});

export const doAccountActivationCleanup = () => ({
  type: DO_ACCOUNT_ACTIVATION_CLEANUP,
});

/********** VERIFY EMAIL **********/

export const verifyEmail = (token) => ({
  type: VERIFY_EMAIL,
  payload: { token },
});

export const verifyEmailOk = response => ({
  type: VERIFY_EMAIL_OK,
  payload: { response },
});

export const verifyEmailErr = error => ({
  type: VERIFY_EMAIL_ERR,
  payload: { error },
});

export const doEmailVerificationCleanup = () => ({
  type: DO_EMAIL_VERIFICATION_CLEANUP,
});