import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';

const MetaTitle = props => {

  const title = Array.isArray(props.children) ? props.children.join('') : props.children;

  return <Helmet title={title} />
}

MetaTitle.propTypes = {
  children: PropTypes.any,
}

export default MetaTitle;