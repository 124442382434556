import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row } from "reactstrap";
import Breadcrumbs from "components/Common/Breadcrumb2";
import MetaTitle from "components/Shared/MetaTitle";
import { getUser, doUserSingleCleanup } from "store/actions";
import { route, routes } from "helpers/routeHelper";
import Error from "pages/Error";
import Preloader from "components/Shared/Preloader";
import { perms, useAccess } from "context/access";
import AccessDenied from "pages/Error/AccessDenied";
import Col from "components/Shared/Col";
import { useAuth } from "context/auth";
import ProfileImage from "./Partial/ProfileImage";
import UserProfileView from "./Profile/View";
import UserForm from "./Partial/Form";
import { getBeUrl } from "helpers/utilHelper";

const UserProfile = () => {

  // redux hook that dispatches actions
  const dispatch = useDispatch();
  // hooks that check permissions
  const { iAmGranted, iAmNotGranted } = useAccess();
  // authenticated user
  const { user: authUser } = useAuth();

  /********** STATE **********/

  // get redux state from the store
  const { user, userError, isBusy } = useSelector(state => state.User.Single);
  // toggle edit-in-place on/off
  const [isEditModeActive, setIsEditModeActive] = useState(false);

  /********** EFFECTS **********/

  // runs once on component mount
  useEffect(() => {
    // make the initial remote call to get the user data
    dispatch(getUser(authUser.id));
    return () => {
      // state cleanup on component unmount
      dispatch(doUserSingleCleanup());
    }
  }, [authUser]);

  /********** OTHER **********/

  const toggleEditMode = () => setIsEditModeActive(prevMode => !prevMode);

  return <React.Fragment>
    {iAmGranted(perms.view_my_account) && <div className="page-content">
      {user && <>
        <MetaTitle>{user.fullName} | My account</MetaTitle>
        <Container fluid>
          <Breadcrumbs title="My account" breadcrumbItems={breadcrumbs(user)} />
          <Row>
            <Col xxxl="8">
              {isEditModeActive && <UserForm title={user.fullName} defaultValues={user} id={parseInt(user.id)} finishedHandler={toggleEditMode} />}
              {!isEditModeActive && <UserProfileView user={user} toggleEditMode={toggleEditMode} />}
            </Col>
            <Col xxxl="4">
              <ProfileImage defaultImage={user.image ? getBeUrl(`user/${user.id}/${user.image}`) : null} />
            </Col>
          </Row>
        </Container>
      </>}
      {isBusy && <Preloader className="inner" />}
      {userError && <Error error={userError} title404="User not found" />}
    </div>}
    {iAmNotGranted(perms.view_my_account) && <AccessDenied />}
  </React.Fragment>
}

const breadcrumbs = user => [{
  title: 'Dashboard',
  url: route(routes.home),
}, {
  title: 'My account',
  url: route(routes.view_me),
}];

export default UserProfile;
