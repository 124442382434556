import React from "react";
import PropTypes from "prop-types";
import { Modal } from "reactstrap";

const TermsOfServiceModal = ({ isOpen, toggle }) => {
  return <Modal
    isOpen={isOpen}
    toggle={toggle}
    scrollable
  >
    <div className="modal-header">
      <h4 className="modal-title mt-0 font-size-18" id="myModalLabel">
        Terms and Conditions
      </h4>
      <button
        type="button"
        onClick={toggle}
        className="close"
        data-dismiss="modal"
        aria-label="Close"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div className="modal-body">
      <h5>Services Provided</h5>
      <p>
        {`Mavsign allows automotive management professionals (“Clients” or “Dealers”) to meet with their vehicle buyers and lessees (“Consumers")
        by assisting in secure document electronic signatures and ID verification.`}
      </p>
      <h5>Verification Criteria</h5>
      <p>
        {`For our identity verification application "VerifyID", is a third-party vendor that provides the following user experience:
        When an image is captured on a mobile device it is automatically sent by the end-user to Maverick's cloud-based application, who in
        turn submits the image to our third party vendor for image processing. A variety of techniques are applied during our vendor's verification
        process to look for evidence of forgery and signs of authenticity and the resulting response that can then be used by Maverick as an indicator
        of whether a document is considered authentic. Maverick then uses that information and shares further the response in almost real time back to
        the Dealer.`}
      </p>
      <h5>Delivery</h5>
      <p>
        {`Maverick HIGHLY recommends that vehicle(s) NOT be delivered until all documents have been signed and returned to Dealer for Dealer's review
        and acceptance and three (3) days have expired to allow time for Dealer to inspect and approve the returned documents. Maverick is not responsible
        in any way for Dealer's decision to deliver a vehicle prior to return of Dealer's documentation, or upon Dealer's receipt of the returned Dealer's
        documents without Dealer's inspection, review or acceptance, or Dealer's failure to independently verify the veracity of customer's identity from the
        documents returned to Dealer.`}
      </p>
      <h5>Performance</h5>
      <p>
        {`Maverick agrees to perform its services in a good and professional manner. While Maverick shall exercise due diligence and prudence in carrying
        through the document signing process, using the identification criteria and procedure stated above, Maverick  does not have a duty to review Dealer's
        documents for accuracy or truth of representation or assess any transaction for possible fraud or other crime by Dealers customer or Consumers.
        Maverick and its services do not investigate fraud and is not a professional law enforcement agency. Maverick will notify Dealer immediately if
        any suspicious fraud or identity theft is detected such as when Dealer's customer or Consumer refuses to provide the identification required or
        requested. EXCEPT AS STATED, MAVERICK EXPRESSLY DISCLAIMS ANY REPRESENTATION, WARRANTY OR SERVICE LEVEL, WHETHER EXPRESS, IMPLIED OR STATUTORY,
        INCLUDING, BUT NOT LIMITED TO, ANY WARRANTIES, DUTIES OR CONDITIONS OF OR RELATED TO MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE.`}
      </p>
      <h5>Fee Schedule</h5>
      <p>
        {`Maverick will provide a written schedule of fees and all services will be charged by Maverick and paid for by Dealer in accordance with the most
        current fee schedule provided by Maverick. Please contact our Sales department for a fee schedule via email at cs@mavsign.com or call 877-708-5095
        and ask for Sales.`}
      </p>
      <h5>Payment</h5>
      <p>
        {`Customer agrees to pay for all services ordered or provided as set forth in Maverick's most current fee schedule at the time the services are
        provided regardless of funding outcome, customer willingness, or other unforeseen events that are not Maverick's fault. Applicable fees will be
        payable to Maverick upon email receipt of invoice and are due and payable and will be paid Net 30 from the day the invoice is received by Dealer.
        Dealer may withhold any fees it disputes in good faith, but shall be required to pay all undisputed fees. Dealer will provide and maintain an invoice
        billing email address and will notify Maverick of any changes in address so that there is no delay in payment. All billing inquiries or changes to billing
        address should be sent to billing@mavsign.com. All payments due to Maverick shall be paid in US currency.`}
      </p>
      <h5>Payment Penalties</h5>
      <p>
        {`A late payment penalty of 5% per month will be assessed on outstanding balances of 15 days or more past due and will be charged if the Dealer does not
        comply with the rates, amounts or dates of pay as set forth in these terms and conditions.`}
      </p>
      <h5>Dealer Delivery of Vehicle</h5>
      <p>
        {`Maverick has no responsibility for Dealer's decision to deliver any vehicle. Maverick strongly recommends against delivery of any vehicle where paperwork
        is incomplete, identity or verification of identity is unclear or suspect or until Dealer has reviewed all returned documents and accepted them as delivered.`}
      </p>
      <h5>Dealer Responsibilities</h5>
      <p>
        {`Dealer has an independent responsibility to verify their customer's or Consumer's identity consistent with automotive management practices. Please refer to
        Dealership's Identity Theft Prevention Program (ITPP) or the FTC's Red Flag Rules for vehicle dealership compliance. Maverick is not responsible or liable for
        any issue or claim relating to the services provided, the completeness of the documents or any loss incurred from the delivery of the vehicle by Dealer and the
        transaction will automatically be considered final, complete and accepted.`}
      </p>
      <h5>Independent Contractor</h5>
      <p>
        {`It is expressly agreed that Maverick is acting as an independent contractor and not as an employee or agent of Dealer in providing its services to Dealer.
        Dealer and Maverick also agree that these services in no way create any partnership or joint venture between them, and Maverick is merely facilitating the signing
        of Dealer's documents for Dealers and their customers' convenience.`}
      </p>
      <h5>Liability</h5>
      <p>
        {`In no event shall Maverick's maximum liability for any claim asserted against it and arising out of any particular circumstance exceed the amount actually paid to
      Maverick for the services provided to Dealer from the circumstance in which the claim arose.`}
      </p>
      <h5>Confidentiality</h5>
      <p>
        {`Both Dealer and Maverick acknowledge that the documents and paperwork provided to Maverick and returned by Maverick to the Dealer contain Dealer's customer's private,
        personal and financial information, may include biometric identification from Dealer's customer, or other confidential and proprietary information regarding Dealer's business.
        Maverick shall not disclose the Dealer's documents or any of the information obtained by Maverick during the signing process to any third parties except upon written consent of
        Dealer or as otherwise permitted by Dealer's customer or Consumer as it relates to the customer's personal information and biometric identification. Dealer warrants to Maverick
        that it shall also maintain the customer's privacy in the customer's personal financial information and shall comply with all applicable privacy laws and policies regarding the
        collection and storage of private personal information and all biometric identifiers Dealer receives from Maverick during the signing process.`}
      </p>
      <h5>Extent of Terms and Conditions</h5>
      <p>
        {`In the event a written service agreement already exists between Maverick and the Dealer, then these Terms and Conditions are intended to complement and amplify the terms of that
        agreement and to the extent that the terms or provisions are inconsistent between these two documents then the terms and conditions provided for here are intended to supersede the
        provisions of any written service agreement. Otherwise, there are no other agreements, representations or warranties, express or implied, made by Maverick altering, modifying or
        adding to the foregoing terms and conditions that are not expressly set forth in these Terms and Conditions.`}
      </p>
      <h5>Governing Law</h5>
      <p>
        {`The performance of the services requested by Dealer and all suits or special proceedings brought arising out of or related to the services provided by Maverick shall be construed
        in accordance with and governed by the laws of the State of California. The sole and exclusive venue for any dispute arising out of or related to the services provided by Maverick
        shall be in the state and federal courts located in and for Orange County, California and Dealer hereby irrevocably consents to the jurisdiction of said courts. The prevailing party
        in any court proceeding or arbitration hereunder shall be entitled to recover their reasonable attorney's fees.`}
      </p>
      <p><strong>{`I acknowledge I have read and agree to all terms and conditions of service and have authority on behalf of the Dealer to accept these Terms and Conditions.`}</strong></p>
    </div>
  </Modal>
}

TermsOfServiceModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
}

export default TermsOfServiceModal;