export default class UserRole {

  // Admin Type
  static ID_MASTER_ADMIN = 100;
  static ID_ADMIN = 101;
  static ID_LEAD_OPS = 102;
  static ID_SCHEDULER = 103;
  static ID_ACCOUNT_MANAGER = 104;
  static ID_DSC = 105;
  // Dealer Type
  static ID_DEALER_GROUP_MANAGER = 200;
  static ID_DEALER_REGIONAL_MANAGER = 201;
  static ID_DEALER_STORE_MANAGER = 202;
  static ID_DEALER_FINANCE_MANAGER = 203;
  static ID_DEALER_SALES_MANAGER = 204;
  // Notary Type
  static ID_NOTARY = 300;
  // Signer Type
  static ID_CUSTOMER_SIGNER = 400;
  static ID_DEALER_SIGNER = 401;
  // Anonymous Type
  static ID_ANONYMOUS = 999;

  static TYPE_ADMIN = 100;
  static TYPE_DEALER = 200;
  static TYPE_NOTARY = 300;
  static TYPE_SIGNER = 400;


  static adminTypeUserIds = [
    this.ID_MASTER_ADMIN,
    this.ID_ADMIN,
    this.ID_LEAD_OPS,
    this.ID_SCHEDULER,
    this.ID_ACCOUNT_MANAGER,
    this.ID_DSC,
  ];

  static dealerUpperManagerIds = [
    this.ID_DEALER_GROUP_MANAGER,
    this.ID_DEALER_REGIONAL_MANAGER,
  ];

  static dealerLowerManagerIds = [
    this.ID_DEALER_REGIONAL_MANAGER,
    this.ID_DEALER_STORE_MANAGER,
  ];

  static dealerManagerIds = [
    ...this.dealerUpperManagerIds,
    this.ID_DEALER_STORE_MANAGER,
  ];

  static dealerStoreLimitedUserIds = [
    this.ID_DEALER_FINANCE_MANAGER,
    this.ID_DEALER_SALES_MANAGER,
  ];

  static dealerStoreUserIds = [
    ...this.dealerStoreLimitedUserIds,
    this.ID_DEALER_STORE_MANAGER,
  ];

  static unregisteredUserIds = [
    this.ID_CUSTOMER_SIGNER,
    this.ID_DEALER_SIGNER,
    this.ID_ANONYMOUS,
  ];

  static isMasterAdmin = roleId => roleId == this.ID_MASTER_ADMIN;

  static isAdmin = roleId => roleId == this.ID_ADMIN;

  static isLeadOps = roleId => roleId == this.ID_LEAD_OPS;

  static isScheduler = roleId => roleId == this.ID_SCHEDULER;

  static isAccountManager = roleId => roleId == this.ID_ACCOUNT_MANAGER;

  static isDsc = roleId => roleId == this.ID_DSC;


  static isDealerGroupManager = roleId => roleId == this.ID_DEALER_GROUP_MANAGER;

  static isDealerRegionalManager = roleId => roleId == this.ID_DEALER_REGIONAL_MANAGER;

  static isDealerStoreManager = roleId => roleId == this.ID_DEALER_STORE_MANAGER;

  static isDealerFinanceManager = roleId => roleId == this.ID_DEALER_FINANCE_MANAGER;

  static isDealerSalesManager = roleId => roleId == this.ID_DEALER_SALES_MANAGER;


  static isDealerUpperManager = roleId => this.dealerUpperManagerIds.includes(roleId);

  static isDealerLowerManager = roleId => this.dealerLowerManagerIds.includes(roleId);

  static isDealerManager = roleId => this.dealerManagerIds.includes(roleId);

  static isDealerStoreUser = roleId => this.dealerStoreUserIds.includes(roleId);

  static isDealerStoreLimitedUser = roleId => this.dealerStoreLimitedUserIds.includes(roleId);


  static isNotary = roleId => roleId == this.ID_NOTARY;


  static isCustomerSigner = roleId => roleId == this.ID_CUSTOMER_SIGNER;

  static isDealerSigner = roleId => roleId == this.ID_DEALER_SIGNER;


  static isAdminType = roleId => this.adminTypeUserIds.includes(roleId);

  static isDealerType = roleId => this.isDealerUpperManager(roleId)
    || this.isDealerStoreUser(roleId);

  static isNotaryType = roleId => this.isNotary(roleId);

  static isSignerType = roleId => this.isCustomerSigner(roleId)
    || this.isDealerSigner(roleId);

  static isNotRegistered = roleId => this.unregisteredUserIds.includes(roleId);
}