import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody, CardHeader, CardTitle, } from "reactstrap";
import classnames from "classnames";
import Dropzone from "react-dropzone";
import trashIco from "../../../assets/images/trash-cutout.svg";
import { deleteAuthUserImg, doAuthUserImgCleanup, refreshAuthUser, uploadAuthUserImg } from "store/actions";
import { showError, showSuccess } from "helpers/utilHelper";
import SpinnerChase from "components/Shared/SpinnerChase";
import Confirmation from "components/Shared/Confirmation";

const ProfileImage = props => {

  // redux hook that dispatches actions
  const dispatch = useDispatch();

  /********** STATE **********/

  // get redux state from the store
  const { isBusy, uploaded, deleted } = useSelector(state => state.User.Image);
  const [selectedFile, setSelectedFile] = useState(props.defaultImage ? { preview: props.defaultImage } : null);
  // is the confirmation dialog visible or not
  // used to show/hide the delete warning
  const [isConfirmationVisible, setIsConfirmationVisible] = useState(false);

  /********** EFFECTS **********/

  // runs once on component mount
  useEffect(() => {
    return () => {
      if (selectedFile) {
        URL.revokeObjectURL(selectedFile.preview);
      }
      dispatch(doAuthUserImgCleanup());
    }
  }, []);

  // runs whenever the 'uploaded' flag changes
  // which happens after an upload-image attempt
  useEffect(() => {
    if (uploaded === true) {
      showSuccess(`Profile image has been updated`);
      // re-fetch the user information from api so the user image is updated in the profile menu
      dispatch(refreshAuthUser());
    } else if (uploaded === false) {
      showError('Unable to update profile image');
    }
  }, [uploaded]);

  // runs whenever the 'deleted' flag changes
  // which happens after a delete-image attempt
  useEffect(() => {
    if (deleted === true) {
      showSuccess(`Profile image has been deleted`);
      setSelectedFile(null);
      // re-fetch the user information from api so the user image is updated in the profile menu
      dispatch(refreshAuthUser());
    } else if (deleted === false) {
      showError('Unable to delete profile image');
    }
  }, [deleted]);

  /********** EVENT HANDLERS **********/

  const onDrop = files => {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
      })
    )
    setSelectedFile(files[0]);
    const formData = new FormData();
    formData.append('userimg', files[0]);
    dispatch(uploadAuthUserImg(formData));
  }

  // make the remote call to delete the image
  const removeImage = () => dispatch(deleteAuthUserImg());

  /********** OTHER **********/

  return <React.Fragment>
    <Card className="expand-v">
      <CardHeader className="bg-transparent pt-3 pb-0">
        <CardTitle>Profile image</CardTitle>
      </CardHeader>
      <CardBody>
        {selectedFile && <div className="profile-dropzone-preview mb-2">
          <div className={classnames('profile-dropzone-preview-inner bg-light', { 'is-busy': isBusy })}>
            <div className="profile-dropzone-preview-bg" style={{ backgroundImage: 'url(' + selectedFile.preview + ')' }}></div>
            <button type="button" className="profile-dropzone-clear" onClick={() => { setIsConfirmationVisible(true) }}>
              <img src={trashIco} />
            </button>
            <div className="profile-dropzone-busy">
              <SpinnerChase className="profile-dropzone-spinner" />
            </div>
          </div>
        </div>}
        {!selectedFile && <Dropzone onDrop={onDrop} multiple={false} accept="image/jpeg,image/png">
          {({ getRootProps, getInputProps }) => (
            <div className="dropzone profile-dropzone">
              <div className="dz-message needsclick" {...getRootProps()}>
                <input {...getInputProps()} />
                <div className="dz-message-inner">
                  <i className="display-4 text-muted bx bx-image-add mb-3" />
                  <h4>Drop image here or click to browse</h4>
                </div>
              </div>
            </div>
          )}
        </Dropzone>}
      </CardBody>
    </Card>
    {isConfirmationVisible && <Confirmation
      confirmBtnText="Delete"
      onConfirm={() => {
        setIsConfirmationVisible(false);
        removeImage();
      }}
      onCancel={() => setIsConfirmationVisible(false)}>
      Are you sure you want to delete the image?
    </Confirmation>}
  </React.Fragment>
}

ProfileImage.propTypes = {
  defaultImage: PropTypes.string,
};

export default ProfileImage;
