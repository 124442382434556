import { toUserModel } from "helpers/utilHelper";
import { combineReducers } from "redux";
import {
  GET_USER,
  GET_USER_OK,
  GET_USER_ERR,
  DO_USER_SINGLE_CLEANUP,
  SAVE_AUTH_USER,
  SAVE_USER_OK,
  SAVE_USER_ERR,
  DO_USER_FORM_CLEANUP,
  SAVE_AUTH_USER_PWD,
  UPLOAD_AUTH_USER_IMG,
  DELETE_AUTH_USER_IMG,
  DO_AUTH_USER_IMG_CLEANUP,
  UPLOAD_AUTH_USER_IMG_OK,
  UPLOAD_AUTH_USER_IMG_ERR,
  DELETE_AUTH_USER_IMG_OK,
  DELETE_AUTH_USER_IMG_ERR,
  REQUEST_PASSWORD_RESET,
  REQUEST_PASSWORD_RESET_OK,
  REQUEST_PASSWORD_RESET_ERR,
  RESET_PASSWORD,
  RESET_PASSWORD_OK,
  RESET_PASSWORD_ERR,
  DO_PASSWORD_RESET_CLEANUP,
  PRE_ACTIVATE_ACCOUNT,
  PRE_ACTIVATE_ACCOUNT_OK,
  PRE_ACTIVATE_ACCOUNT_ERR,
  PRE_RESET_PASSWORD,
  PRE_RESET_PASSWORD_OK,
  PRE_RESET_PASSWORD_ERR,
  ACTIVATE_ACCOUNT,
  ACTIVATE_ACCOUNT_OK,
  ACTIVATE_ACCOUNT_ERR,
  DO_ACCOUNT_ACTIVATION_CLEANUP,
  VERIFY_EMAIL,
  VERIFY_EMAIL_OK,
  VERIFY_EMAIL_ERR,
  DO_EMAIL_VERIFICATION_CLEANUP,
  DO_JWT_VALIDATION_CLEANUP,
} from "./actionTypes";

/********** SINGLE **********/

const defaultSingleState = {
  user: null, // entity data
  userError: null, // load-user operation error
  deleted: null, // whether the entity has been successfully deleted (must be NULL by default)
  deleteError: null, // delete-user operation error
  isBusy: false, // whether a remote request is in progress
};

const Single = (state = defaultSingleState, action) => {
  switch (action.type) {
    // load
    case GET_USER:
      state = {
        ...state,
        userError: null,
        isBusy: true,
      };
      break
    case GET_USER_OK:
      state = {
        ...state,
        user: toUserModel(action.payload.response.user),
        isBusy: false,
      };
      break
    case GET_USER_ERR:
      state = {
        ...state,
        user: null,
        userError: action.payload.error,
        isBusy: false,
      };
      break
    // cleanup
    case DO_USER_SINGLE_CLEANUP:
      state = { ...defaultSingleState };
      break
  }
  return state;
}

/********** FORM **********/

const defaultFormState = {
  user: null,
  saved: null,
  saveError: null,
  isBusy: false,
};

const Form = (state = defaultFormState, action) => {
  switch (action.type) {
    case SAVE_AUTH_USER:
    case SAVE_AUTH_USER_PWD:
      state = {
        ...state,
        user: toUserModel(action.payload.data),
        saved: null,
        saveError: null,
        isBusy: true,
      };
      break
    case SAVE_USER_OK:
      state = {
        ...state,
        saved: true,
        isBusy: false,
      };
      break
    case SAVE_USER_ERR:
      state = {
        ...state,
        saved: false,
        saveError: action.payload.error,
        isBusy: false,
      };
      break
    case DO_USER_FORM_CLEANUP:
      state = { ...defaultFormState };
      break
  }
  return state;
}

/********** IMAGE **********/

const defaultImageState = {
  uploaded: null,
  uploadError: null,
  deleted: null,
  deleteError: null,
  isBusy: false,
};

const Image = (state = defaultImageState, action) => {
  switch (action.type) {
    case UPLOAD_AUTH_USER_IMG:
      state = {
        ...state,
        uploaded: null,
        uploadError: null,
        isBusy: true,
      };
      break
    case UPLOAD_AUTH_USER_IMG_OK:
      state = {
        ...state,
        uploaded: true,
        isBusy: false,
      };
      break
    case UPLOAD_AUTH_USER_IMG_ERR:
      state = {
        ...state,
        uploaded: false,
        uploadError: action.payload.error,
        isBusy: false,
      };
      break
    case DELETE_AUTH_USER_IMG:
      state = {
        ...state,
        deleted: null,
        deleteError: null,
        isBusy: true,
      };
      break
    case DELETE_AUTH_USER_IMG_OK:
      state = {
        ...state,
        deleted: true,
        isBusy: false,
      };
      break
    case DELETE_AUTH_USER_IMG_ERR:
      state = {
        ...state,
        deleted: false,
        deleteError: action.payload.error,
        isBusy: false,
      };
      break
    case DO_AUTH_USER_IMG_CLEANUP:
      state = { ...defaultImageState };
      break
  }
  return state;
}

/********** VALIDATE JWT **********/

const defaultValidateJwtState = {
  payload: null,
  valid: null,
  validationError: null,
  isValidationInProgress: false,
}

const ValidateJwt = (state = defaultValidateJwtState, action) => {
  switch (action.type) {
    case PRE_ACTIVATE_ACCOUNT:
    case PRE_RESET_PASSWORD:
      state = {
        ...state,
        payload: null,
        valid: null,
        validationError: null,
        isValidationInProgress: true,
      };
      break
    case PRE_ACTIVATE_ACCOUNT_OK:
    case PRE_RESET_PASSWORD_OK:
      state = {
        ...state,
        payload: action.payload.response,
        valid: true,
        isValidationInProgress: false,
      };
      break
    case PRE_ACTIVATE_ACCOUNT_ERR:
    case PRE_RESET_PASSWORD_ERR:
      state = {
        ...state,
        valid: false,
        validationError: action.payload.error,
        isValidationInProgress: false,
      };
      break
    case DO_JWT_VALIDATION_CLEANUP:
      state = { ...defaultValidateJwtState };
      break
  }
  return state;
}

/********** RESET PASSWORD **********/

const defaultResetPasswordState = {
  sent: null,
  sendError: null,
  isSendInProgress: false,
  reset: null,
  resetError: null,
  isResetInProgress: false,
}

const ResetPassword = (state = defaultResetPasswordState, action) => {
  switch (action.type) {
    // request password reset
    case REQUEST_PASSWORD_RESET:
      state = {
        ...state,
        sent: null,
        sendError: null,
        isSendInProgress: true,
      };
      break
    case REQUEST_PASSWORD_RESET_OK:
      state = {
        ...state,
        sent: true,
        isSendInProgress: false,
      };
      break
    case REQUEST_PASSWORD_RESET_ERR:
      state = {
        ...state,
        sent: false,
        sendError: action.payload.error,
        isSendInProgress: false,
      };
      break
    // reset password
    case RESET_PASSWORD:
      state = {
        ...state,
        reset: null,
        resetError: null,
        isResetInProgress: true,
      };
      break
    case RESET_PASSWORD_OK:
      state = {
        ...state,
        reset: true,
        isResetInProgress: false,
      };
      break
    case RESET_PASSWORD_ERR:
      state = {
        ...state,
        reset: false,
        resetError: action.payload.error,
        isResetInProgress: false,
      };
      break
    // cleanup
    case DO_PASSWORD_RESET_CLEANUP:
      state = { ...defaultResetPasswordState };
      break
  }
  return state;
}

/********** ACTIVATE ACCOUNT **********/

const defaultActivateAccountState = {
  activated: null,
  activationError: null,
  isActivationInProgress: null,
};

const ActivateAccount = (state = defaultActivateAccountState, action) => {
  switch (action.type) {
    case ACTIVATE_ACCOUNT:
      state = {
        ...state,
        activated: null,
        activationError: null,
        isActivationInProgress: true,
      };
      break
    case ACTIVATE_ACCOUNT_OK:
      state = {
        ...state,
        activated: true,
        isActivationInProgress: false,
      };
      break
    case ACTIVATE_ACCOUNT_ERR:
      state = {
        ...state,
        activated: false,
        activationError: action.payload.error,
        isActivationInProgress: false,
      };
      break
    case DO_ACCOUNT_ACTIVATION_CLEANUP:
      state = { ...defaultActivateAccountState };
      break
  }
  return state;
}

/********** VERIFY EMAIL **********/

const defaultVerifyEmailState = {
  verified: null,
  verificationError: null,
  isVerificationInProgress: false,
};

const VerifyEmail = (state = defaultVerifyEmailState, action) => {
  switch (action.type) {
    case VERIFY_EMAIL:
      state = {
        ...state,
        verified: null,
        verificationError: null,
        isVerificationInProgress: true,
      };
      break
    case VERIFY_EMAIL_OK:
      state = {
        ...state,
        verified: true,
        isVerificationInProgress: false,
      };
      break
    case VERIFY_EMAIL_ERR:
      state = {
        ...state,
        verified: false,
        verificationError: action.payload.error,
        isVerificationInProgress: false,
      };
      break
    case DO_EMAIL_VERIFICATION_CLEANUP:
      state = { ...defaultVerifyEmailState };
      break
  }
  return state;
}

export default combineReducers({
  Single,
  Form,
  Image,
  ValidateJwt,
  ResetPassword,
  ActivateAccount,
  VerifyEmail,
})
